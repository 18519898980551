import { ConfigStateService } from '@medlogic/shared/state-config';
import { EnumAtividadeTipo } from '../../shared/enum/enum-atividade-tipo.enum';
import { AtividadeViewComponent } from '../atividade-view/atividade-view.component';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { EnBubbleEvent } from '../../shared/enum/en-bubble-event.enum';
import { ActivatedRoute } from '@angular/router';
import { AtividadeDAL } from '../../shared/model/dal/atividade-dal';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AtividadeComponenteDAL } from '../../shared/model/dal/atividade-componente-dal';
import { CalculadoraService } from '../../shared/service/calculadora.service';
import { DadoDAL } from '../../shared/model/dal/dado-dal';
import { CalculatorService } from '../../shared/service/calculator.service';
import { ValidatorService } from '../../shared/service/validator.service';
import { ActionService } from '../../shared/service/action.service';
import { CalculadoraConditionService } from '../../shared/service/calculadora-condition.service';
import { NavigationService } from '../../shared/service/navigation.service';
import { LibService } from '../../shared/service/lib.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { UsuarioDAL, WsTrackerService } from '@medlogic/shared/shared-data-access';
import { ProcessoDAL } from '../../shared/model/dal/processo-dal';
import { MatDialog } from '@angular/material/dialog';
import { mergeMap, tap } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { GlobalService, ConfigJsonService, IAtividadeComponenteDAL, IOcorrencia, LogService, MsgPtBR } from '@medlogic/shared/shared-interfaces';
import { OcorrenciaDAL } from '../../shared/model/dal/ocorrencia-dal';
import { ExecucaoTarefaDAL } from '../../shared/model/dal/execucao-tarefa-dal';
import { EnActivityType } from '@medlogic/shared/gecore';
import { IAtividade } from '../../shared/interface/iatividade';

/**
 * Essa classe é executada quando ocorre a Atividade é do tipo Cadastro.
 * A princípio todas as atividades do ERP são deste tipo, ou Cadastro-detail.
 * Atividades não associadas a cadastro existirão apenas quando houver a possibilidade de execução
 * de um fluxo completo do GE.
 */
@Component({
  selector: 'lib-atividade-cadastro-view',
  templateUrl: './atividade-cadastro-view.component.html',
  styleUrls: ['./../atividade-view/atividade-view.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AtividadeCadastroViewComponent extends AtividadeViewComponent implements OnInit {

  backOcorrenciaNo: number;
  backAtividadeNo: number;

  private _enableBack: boolean;
  public get enableBack(): boolean {
    if (this._enableBack) {
      return this._enableBack;
    }
  }
  public set enableBack(v: boolean) {
    this._enableBack = v;
  }

  constructor(
    global: GlobalService,
    config: ConfigStateService,
    cnfJson: ConfigJsonService,
    route: ActivatedRoute,
    log: LogService,
    ocorrenciaDAL: OcorrenciaDAL,
    atividadeDAL: AtividadeDAL,
    fb: FormBuilder,
    atividadeComponenteDAL: AtividadeComponenteDAL,
    calc: CalculadoraService,
    dadoDAL: DadoDAL,
    calculator: CalculatorService,
    validator: ValidatorService,
    action: ActionService,
    calcCond: CalculadoraConditionService,
    navigation: NavigationService,
    lib: LibService,
    confirmationService: ConfirmationService,
    wsTracker: WsTrackerService,
    execucaoTarefa: ExecucaoTarefaDAL,
    usuarioDAL: UsuarioDAL,
    processoDAL: ProcessoDAL,
    matDialog: MatDialog,
    msg: MsgPtBR,
    messageService: MessageService,
  ) {
    super(
      global,
      config,
      cnfJson,
      route,
      log,
      ocorrenciaDAL,
      atividadeDAL,
      fb,
      atividadeComponenteDAL,
      calc,
      dadoDAL,
      calculator,
      validator,
      action,
      calcCond,
      navigation,
      lib,
      confirmationService,
      wsTracker,
      execucaoTarefa,
      usuarioDAL,
      processoDAL,
      matDialog,
      msg,
      messageService,
    );
    this.activityType = EnActivityType.List;
  }

  /* override  */
  ngOnInit(): void {
    try {
      super.ngOnInit();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  // Override
  /**
   * Recarrega a tela
   */
  protected refresh(
    enAtividadeTipo: EnumAtividadeTipo,
    ano: number,
    ono: number,
    uno: number,
    defaultFormControls: any,
    addToHistory: boolean = true,
    pno: number = -1,
    tno: number = -1
  ): Observable<IAtividade> {
    try {
      this.isEditMode = ono > 0;
      this.enableBack = this.isEditMode;
      this.trackLoading(ano);
      this.config.observableNewOcorrenciaNo = this.criarOcorrenciaSeNecessario(ono);

      const superRefresh = (
        parEnAtividadeTipo: EnumAtividadeTipo,
        parAno: number,
        parUno: number,
        parDefaultFormControls: any,
        parAddToHistory: boolean,
        parPno: number) => mergeMap((newOno: number) => {
          this.config.OcorrenciaNo.next(newOno);
          this.ocorrenciaNo = newOno;

          return super.refresh(parEnAtividadeTipo, parAno, newOno, parUno, parDefaultFormControls, parAddToHistory, parPno);
        });

      const afterRefresh = () => tap((atividade: IAtividade) => {
        this.atividade = atividade;
        this.atividadeNome = atividade.Nome;
        if (addToHistory) {
          const route = this.navigation.getRouteListEdit(
            atividade.AtividadeNo,
            atividade.OcorrenciaNo,
            false,
            this.saveInList
          );
          this.navigation.addToHistory(
            route,
            atividade && atividade.Nome ? atividade.Nome : this.msg.MODULE_NAME_ATIVIDADE
          );
        }
      });

      return this.config.observableNewOcorrenciaNo
        .pipe(
          superRefresh(enAtividadeTipo, ano, uno, defaultFormControls, addToHistory, pno),
          afterRefresh(),
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'refresh', error.message);
    }
  }

  // override
  /**
   * Exclui um item do cadastro
   */
  protected listDelete(
    $event: any,
    ano: number,
    ono: number,
    tno: number,
    uno: number,
    pno: number,
    componentes: IAtividadeComponenteDAL[]
  ): void {
    try {
      this.subs.sink = this.execucaoTarefa
        .deleteCadastro(ono, ano)
        .pipe(
          mergeMap((deleteItem) => {
            this.afterDelete(
              EnBubbleEvent.listDelete,
              this.tarefaNo,
              ano,
              ono,
              this.config.usuarioLogadoNo,
              -1,
              this.componentes
            );

            return of(deleteItem);
          })
        )
        .subscribe();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'listDelete', error.message);
    }
  }

  // override
  /**
   * Emite um evento para a edição, por sua vez, será aberta a janela de diálogo com a edição.
   */
  protected listDetailEdit(
    $event: any,
    ano: number,
    ono: number,
    listvno: number,
    isReadOnly: boolean,
    saveInList: boolean
  ): void {
    try {
      this.subs.sink = this.salvar(
        EnBubbleEvent.activitySave,
        this.componentes,
        this.tarefaNo,
        this.config.OcorrenciaNo.value,
        this.config.usuarioLogadoNo,
        this.config.processoNo
      ).subscribe(() => {
        this.tabActivedId = 0;
      });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'listEdit', error.message);
    }
  }

  /**
   * Verifica se existe um item modificado (retorno de edição de item de grid) e atualiza o valor caso haja
   * Quando um item de grid é acrescentado ou editado, a propriedade config.gridItems é preenchida.
   * A partir dela é que se deve criar ou modificar o grid.
   * atividadeCadastroAssociadaNo: É o cadastro associado ao grid.
   * gridItemsFromChildActivity: são os itens de transporte de edição/inserção de item, que estão retornando para o grid principal.
   */
  protected updateGridItems(
    ctrl: IAtividadeComponenteDAL,
    currentItems: any[],
    gridItemsComponentes: IAtividadeComponenteDAL[]
  ): any[] {
    try {

    } catch (error) {
      this.log.Registrar(this.constructor.name, 'addGridItemFromMemory', error.message);
    }
    return null;
  }

  // override
  /**
   * Roteia para a criação de um novo item do tipo Cadastro. Salva os dados da Atividade atual antes.
   */
  protected listDetailNew(ano: number, listvno: number, saveInList: boolean): void {
    try {
      this.subs.sink = this.salvar(
        EnBubbleEvent.activitySave,
        this.componentes,
        this.tarefaNo,
        this.config.OcorrenciaNo.value,
        this.config.usuarioLogadoNo,
        this.config.processoNo
      ).subscribe(() => {
        this.tabActivedId = 0;
      });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'listNew', error.message);
    }
  }

  /**
   * Além de salvar a atividade atual, promoverá um refresh da mesma atividade e cria nova ocorrencia.
   */
  protected listDetailSaveAndNew(
    ano: number,
    saveInList: boolean,
    addToHistory: boolean = true,
    preserveValues: boolean = true
  ): void {
    try {
      this.subs.sink = this.salvar(
        EnBubbleEvent.activitySave,
        this.componentes,
        this.tarefaNo,
        this.config.OcorrenciaNo.value,
        this.config.usuarioLogadoNo,
        this.config.processoNo
      ).pipe(
        mergeMap(() => {
          this.tabActivedId = 0;
          this.config.OcorrenciaNo.next(-1);
          return this.refresh(
            EnumAtividadeTipo.Editar,
            this.config.ModeloAtividadeNo,
            this.config.OcorrenciaNo.value,
            this.config.usuarioLogadoNo,
            this.config.getDefaultFormControls(ano),
            addToHistory,
            this.config.processoNo
          );
        })
      ).subscribe();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'listDetailSaveAndNew', error.message);
    }
  }

  // override
  /**
   * Evento chamado após a o salvamento da atividade. Poderá ser sobrescrito.
   */
  protected afterSave(
    enBubbleEvent: EnBubbleEvent,
    tno: number,
    ano: number,
    ono: number,
    uno: number,
    pno: number,
    componentes: IAtividadeComponenteDAL[],
    fg: FormGroup,
    isEditMode: boolean
  ): Observable<IOcorrencia> {
    try {
      return super.afterSave(enBubbleEvent, tno, ano, ono, uno, pno, componentes, fg, isEditMode)
        .pipe(
          this.error()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'afterSave', error.message);
    }
    return of(null);
  }

  /**
   * Verifica se a Ocorrencia é -1 (nova), ou então se é existente
   */
  newOrExists(): boolean {
    try {
      return this.componentes && this.componentes.length > 0;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'newOrExists', error.message);
    }
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IConfigModule } from '../interface/iconfig-modules';
import { EnComponentsView } from '../enum/en-components-view';

import * as moment_ from 'moment';
import { LogService } from './log.service';
const moment = moment_; // Necessário dado a problema de compilação

/**
 * Conceitualmente, deveria ser utilizado apenas para armazenar propriedades carregadas do config.json
 */
@Injectable({ providedIn: 'root' })
export class ConfigJsonService {

    // Licença atualizada em 17/12/2018
    HANDSOME_TABLE_LICENSE_KEY = '2c9a6-e1dd1-3b145-34f36-8880d';
    // O novo componente não recebe a licença por meio de parâmetro.
    project = '';
    kitchenCategoryName: string;
    iisApplicationName = ''; // Necessário para identificar qual o primeiro nível da aplicação. Se for vazio, irá ignorar (debug)
    protocol: string;
    subdominio: string;
    dominio: string;
    imgBasePath = '';
    // host: string = window.location.host;
    configUrl = './assets/config.json';
    loginUrl = '/Intranet/Account/LogOn?ReturnUrl=';

    showVariavelNo = false;
    showFormulasDependenceList = true;
    showOnlySaveButton = false;

    showFormErrors = false;
    showInvisible = false;
    isDebug = false;
    forceAllControlsEditable = false;
    listOfMethodsToLog = [];
    admUserRoleNo = null;
    saveWebServiceLog = false;
    showFormulasRendering = false;
    showTabOrder = false;
    modules = new Array<IConfigModule>();
    disableRequiredValidation = false;
    emailFrom = '';
    enableMobileInputFocus = false;
    baseUrlAudio = '';
    alwaysReload = false; // Toda vez que acessar a página principal os dados são recarregados
    // tslint:disable-next-line: variable-name
    features: any;
    private _baseUrlAPINew: string;
    public get baseUrlAPINew(): string {
        return `${this._baseUrlAPINew}api/`;
    }
    public set baseUrlAPINew(v: string) {
        if (!v.startsWith('http')) {
            v = `${this.protocol}://${v}`;
        }
        if (!v.endsWith('/')) {
            v = `${v}/`;
        }
        this._baseUrlAPINew = v;
    }

    // tslint:disable: variable-name

    private _baseUrlWebservice: string;
    public get baseUrlWebservice(): string {
        return this._baseUrlWebservice;
    }
    public set baseUrlWebservice(v: string) {
        this._baseUrlWebservice = v;
    }

    private _baseUrlWebapi: string;
    public get baseUrlWebapi(): string {
        return this._baseUrlWebapi;
    }
    public set baseUrlWebapi(v: string) {
        this._baseUrlWebapi = v;
    }
    private http: HttpClient;

    /* Name of the original CAD - IDOSO BEM CUIDADO. */
    private _cadConfigName: string = null;
    public get cadConfigName(): string {
        return this._cadConfigName;
    }
    public set cadConfigName(v: string) {
        this._cadConfigName = v;
    }


    public attachmentUrl(ono: number, ano: number, uno: number, height: string): string {
        // tslint:disable: max-line-length
        return `${this
            .baseUrlIntranet}InterfaceWeb/LoadAnexo?ocno=${ono}&atvno=${ano}&userno=${uno}&noCache=${Math.random()}&loadFileFlash=true&checkResize=${true}&height=${height}&AnexoDirect=false`;
    }


    public get baseUrl(): string {
        const subdom = this.subdominio ? `${this.subdominio}.` : '';
        return `${this.protocol}://${subdom}${this.dominio}`;
    }

    public get baseUrlApp(): string {
        return window.location.origin;
    }

    public get wsdlUrl(): string {
        // if (this.baseUrlWebservice) {
        //   return `${this.baseUrlWebservice}/Service1.asmx?wsdl`;
        // }
        // return `${this.baseUrl}/webservice/Service1.asmx?wsdl`;
        // TODO: Início processo de mudança da intermediação API
        // return 'http://localhost:3333/api/PostWebservice'
        return `${this.baseUrlAPI}/api/`;
    }

    public get baseUrlIntranet(): string {
        return `${this.baseUrl}/Intranet/`;
    }

    public get baseUrlAPI(): string {
        if (this.baseUrlWebapi) {
            return `${this.baseUrlWebapi}/api/`;
        }
        return `${this.baseUrl}/WebApi/api/`; // WebApiService //WebApi
    }

    public get baseUrlWebAPIService(): string {
        return `${this.baseUrl}/WebApiService/api/`; //  "../WebApiService/api/"
    }

    public get baseUrlIconProcess(): string {
        return `${this.imgBasePath}/Arquivos/Icon/`;
    }

    // tslint:disable: variable-name
    // tslint:disable: member-ordering
    _baseUrlDocGen: string;
    public set baseUrlDocGen(v: string) {
        this._baseUrlDocGen = v;
    }

    public get baseUrlDocGen(): string {
        return `${this._baseUrlDocGen}/api/`;
    }

    private _apiDocGenCode: string;
    public get apiDocGenCode(): string {
        return this._apiDocGenCode;
    }
    public set apiDocGenCode(v: string) {
        this._apiDocGenCode = v;
    }

    instanceVersion = 0;

    /********* FIM GE ****************/

    public get baseUrlFileUpload(): string {
        return '';
        // return `${this.baseUrlAPI}Data/SetUploadFileAssets?token=${this.baseUsuarioToken}`;
    }

    /********* FIM ERP ****************/

    constructor(
      private httpClient: HttpClient
    ) {
      try {
          // Agora, injetando diretamente o HttpClient
          this.http = httpClient;
          this.instanceVersion++;
      } catch (error) {
          console.error(this.constructor.name, 'constructor', error.message);
      }
  }

    /*Método de inicialização que deve ser chamado no primeiro módulo carregado do projeto.
       * É necessário que retorne um Promise para assegurar que o método retornará as configurações
       * antes que o construtor da classe ativada por bootstrap seja chamado.
       */
    init(token: string = null): Promise<any> {
        try {
            const cfg = this.getConfig();
            cfg.then(() => {
            });
            return cfg;
        } catch (error) {
            console.error(this.constructor.name, 'init', error.message);
        }
    }


    /* Faz a leitura do config.json com as configurações.  */
    protected getConfig(): Promise<any> {
        try {
            const result = this.http.get<any>(this.configUrl).toPromise();
            result.then((s) => {
                try {
                    this.imgBasePath = s.imgBasePath;
                    if (s.protocol) {
                        this.protocol = s.protocol;
                    }
                    this.subdominio = s.subdominio;
                    this.dominio = s.dominio;
                    this.iisApplicationName = s.iisApplicationName;
                    this.kitchenCategoryName = s.kitchenCategoryName;
                    if (s.project) {
                        this.project = s.project;
                    }
                    if (s.hasOwnProperty('showOnlySaveButton')) {
                        this.showOnlySaveButton = s.showOnlySaveButton;
                    }
                    // GE
                    this.showVariavelNo = s.showVariavelNo;
                    this.showFormulasDependenceList = s.showFormulasDependenceList;
                    this.showFormErrors = s.showFormErrors;
                    this.showInvisible = s.showInvisible;
                    this.isDebug = s.isDebug;
                    if (s.forceAllControlsEditable !== undefined) {
                        this.forceAllControlsEditable = s.forceAllControlsEditable;
                    }
                    if (s.listOfMethodsToLog !== undefined) {
                        this.listOfMethodsToLog = s.listOfMethodsToLog;
                    }
                    if (s.admUserRoleNo !== undefined) {
                        this.admUserRoleNo = s.admUserRoleNo;
                    }
                    if (s.saveWebServiceLog !== undefined) {
                        this.saveWebServiceLog = s.saveWebServiceLog;
                    }
                    this.showFormulasRendering = s.showFormulasRendering;
                    this.showTabOrder = s.showTabOrder;
                    if (s.baseUrlAPINew) {
                        this.baseUrlAPINew = s.baseUrlAPINew;
                    }
                    if (s.baseUrlDocGen) {
                        this.baseUrlDocGen = s.baseUrlDocGen;
                    }
                    if (s.apiDocGenCode) {
                        this.apiDocGenCode = s.apiDocGenCode;
                    }
                    if (s.baseUrlWebservice) {
                        this.baseUrlWebservice = s.baseUrlWebservice;
                    }
                    if (s.baseUrlWebapi) {
                        this.baseUrlWebapi = s.baseUrlWebapi;
                    }
                    if (s.cadConfigName) {
                        this.cadConfigName = s.cadConfigName;
                    }
                    if (s.disableRequiredValidation) {
                        this.disableRequiredValidation = s.disableRequiredValidation;
                    }
                    if (s.emailFrom) {
                        this.emailFrom = s.emailFrom;
                    }
                    if (s.enableMobileInputFocus) {
                        this.enableMobileInputFocus = s.enableMobileInputFocus;
                    }
                    // Busca Ocorrencia Modules
                    if (s.modules) {
                        this.modules = this.addModules(s.modules);
                    }
                    if (s.alwaysReload) {
                        this.alwaysReload = !!s.alwaysReload;
                    }
                    this.baseUrlAudio = s.baseUrlAudio || window?.location?.href || '';
                    if (s.features) {
                        this.features = s.features;
                    }
                } catch (error) {
                    console.error(this.constructor.name, 'getConfig.then', error.message);
                }
            });
            return result;
        } catch (error) {
            console.error(this.constructor.name, 'getConfig', error.message);
        }
    }

    /* Adiciona as configurações dos módulos, respeitando propriedades padrão. */
    protected addModules(modules: IConfigModule[]): IConfigModule[] {
        try {
            if (modules) {
                return modules.map((m) => {
                    const module = {} as IConfigModule;
                    module.name = m.name?.toUpperCase();
                    module.id = m.id;
                    module.canCreate = m.hasOwnProperty('canCreate') ? m.canCreate : true;
                    module.canCreateIfEmpty = m.hasOwnProperty('canCreateIfEmpty') ? m.canCreateIfEmpty : true;
                    module.canEdit = m.hasOwnProperty('canEdit') ? m.canEdit : true;
                    module.canRead = m.hasOwnProperty('canRead') ? m.canRead : true;
                    module.isCustomerModule = m.hasOwnProperty('isCustomerModule') ? m.isCustomerModule : false;
                    module.mode = m.mode ? EnComponentsView[m.mode?.toUpperCase()] : EnComponentsView.HTML5;
                    module.identification = m.hasOwnProperty('identification') ? m.identification : null;
                    module.derivedFormName = m.hasOwnProperty('derivedFormName') ? m.derivedFormName : null;
                    module.cadNoName = m.hasOwnProperty('cadNoName') ? m.cadNoName : null;
                    module.roles = m.hasOwnProperty('roles') ? m.roles : null;
                    module.sendEmailReportToFamilyAfterSave = m.hasOwnProperty('sendEmailReportToFamilyAfterSave') ? m.sendEmailReportToFamilyAfterSave : false;
                    return module;
                });
            }
        } catch (error) {
            console.error(this.constructor.name, 'addModules', error.message);
        }
    }

    /*Algumas urls precisarão ser substituídas para se adequar ao padrão https.
   * Esse problema se deve ao cadastramento de urls completas no banco de dados.
   */
    changeUrl(urlToBeChanged: string, replaceSubdomain: boolean = false): string {
        try {
            if (!urlToBeChanged) {
                return null;
            }
            if (this.protocol === 'https') {
                urlToBeChanged = urlToBeChanged.replace('http:', 'https:');
            }
            const subdomain = `${this.subdominio}.`;
            if (replaceSubdomain && !urlToBeChanged.indexOf(subdomain)) {
                // subdominio da url diferente do padrão do projeto
                const bar = urlToBeChanged.indexOf('/');
                const ponto = urlToBeChanged.indexOf('.');
                const sub = urlToBeChanged.slice(bar + 1, ponto + 1);
                urlToBeChanged = urlToBeChanged.replace(sub, subdomain);
            }
            return urlToBeChanged;
        } catch (error) {
            console.error(this.constructor.name, 'changeUrl', error.message);
        }
    }

    /* Substitui a url do arquivo, pelo caminho padrão, mantendo apenas o nome do arquivo.
    * O caminho padrão é definido em this.imgBasePath.
    */
    replaceImgPath(urlToBeChanged: string): string {
        try {
            const fileName = this.extractFilenameFromUrl(urlToBeChanged);
            const base = this.imgBasePath.endsWith('/') ? this.imgBasePath : `${this.imgBasePath}/`;
            return `${base}${fileName}`;
        } catch (error) {
            console.error(this.constructor.name, 'replaceImgPath', error.message);
        }
    }


    /* Separa uma url que aponta para um arquivo em partes. */
    protected extractFilenameFromUrl(url: string): string {
        try {
            const res = /(?=[\w-]+\.\w{3,4}$).+/gi.exec(url);
            if (!res) {
                return url;
            }
            return res[0];
        } catch (error) {
            console.error(this.constructor.name, 'extractFilenameFromUrl', error.message);
        }
    }

    getConfigs(attribute: string) {
        try {
            return this.features.find((feature) => attribute in feature) ?? { [attribute]: false, toClients: [] };
        } catch (_error: any) {
          console.error(this.constructor.name, "getConfigs", _error.message);
        }
    }

}

import { Observable } from 'rxjs';
import { AddActivityDialogComponent } from '@medlogic/medlogic/ui/medlogic-ui-care-plan';
import { selectActivitityTypes, IAppMedlogicFhirState, loadActivityTypes, setActivity, setCrud, setActivityType } from '@medlogic/fhir';
import { LogService, EnActionType, EnActivityType, EnCrud, IActivity, IActivityTypeCodeableConcept, UnsubscribeOnDestroyAdapter } from '@medlogic/shared/shared-interfaces';
import { NavigationPwaService } from './../../service/navigation-pwa.service';
import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { selectedPatientId } from '@medlogic/medlogic/medlogic-state';

@Component({
  selector: 'ml-patient-care-plan',
  templateUrl: './patient-care-plan.component.html',
  styleUrls: ['./patient-care-plan.component.css']
})
export class PatientCarePlanComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  activityTypes$: Observable<IActivityTypeCodeableConcept[]> = this.store.pipe(select(selectActivitityTypes));
  selectedPatientId$: Observable<number> = this.store.pipe(select(selectedPatientId));

  constructor(
    private log: LogService,
    private nav: NavigationPwaService,
    private matDialog: MatDialog,
    private store: Store<IAppMedlogicFhirState>,
  ) {
    super();
  }

  ngOnInit() {
    this.store.dispatch(loadActivityTypes());
  }

  onMenuItemClick(option: string): void {
    try {
      switch (option) {
        case 'back':
          this.nav.back();
          break;

        default:
          break;
      }
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, 'onMenuItemClick', error.message);
    }
  }

  onActivityClick(activity: IActivity): void {
    try {
      const selectedId = activity.activityIdentifier + activity?.currentActivityDetail?.activityDetailIdentifier + activity?.currentProgress?.progressIdentifier;;
      const selectedActivityType = activity.ActivityTypeCodeableConcept[0].type as EnActivityType;

      this.store.dispatch(setActivity({ selectedId }));
      this.store.dispatch(setActivityType({ selectedActivityType }));
      this.nav.gotoActivityDetail(selectedId);
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, 'onActivityClick', error.message);
    }
  }

  onActionClick({ actionType }, activityTypes: IActivityTypeCodeableConcept[]): void {
    try {
      switch (actionType) {
        case EnActionType.add:
          this.openAddActivityDialog(activityTypes);
          break;
      }
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, 'onActionClick', error.message);
    }
  }

  private openAddActivityDialog(activityTypes: IActivityTypeCodeableConcept[]): void {
    try {
      const dialog = this.matDialog.open(AddActivityDialogComponent, { data: { activityTypes } });

      this.subs.sink = dialog.afterClosed()
        .subscribe((data: { actionType: EnActionType, selectedActivityType: { name: string, type: string } }) => {
          if (data.actionType === EnActionType.confirm) {
            this.store.dispatch(setActivityType({ selectedActivityType: data?.selectedActivityType?.type as EnActivityType }));
            this.store.dispatch(setCrud({ crud: EnCrud.create, activityType: data?.selectedActivityType?.type as EnActivityType, activityName: data?.selectedActivityType?.name }));
            this.nav.gotoActivityCreate();
          }
        });
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, 'openAddActivityDialog', error.message);
    }
  }
}

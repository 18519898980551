import { IActivity, EnActivityMode, EnShift } from '@medlogic/shared/shared-interfaces';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { IActivityState } from './activity-state';

import * as ActivityActions from './activity.actions';

export const activityFeatureKey = 'activity';

export const adapterActivity: EntityAdapter<IActivity> = createEntityAdapter<IActivity>({
  selectId: instance => instance.activityIdentifier + instance?.currentActivityDetail?.activityDetailIdentifier + instance?.currentProgress?.progressIdentifier,
  sortComparer: sortOnOcurrencyAndNames
});

export const initialStateActivity: IActivityState = adapterActivity.getInitialState({
  activityTypes: null,
  selectedId: null,
  selectedActivityMode: EnActivityMode.CarePlan,
  selectedShift: EnShift.all,
  selectedCrud: null,
  selectedActivityType: null,
  isLoading: true,
});

function sortOnOcurrencyAndNames(a: IActivity, b: IActivity) {
  if (a.medlogicId === b.medlogicId) {
    return a.activityName < b.activityName ? -1 : 1;
  } else {
    return a.medlogicId ? -1 : 1;
  }
}

export const reducer = createReducer(
  initialStateActivity,
  on(ActivityActions.addActivity,
    (state, action) => adapterActivity.addOne(action.activity, state)
  ),
  on(ActivityActions.setShift,
    (state, action) => ({ ...state, selectedShift: action.shift })
  ),
  on(ActivityActions.setCrud,
    (state, action) => ({ ...state, selectedId: null, selectedCrud: action.crud, selectedActivityType: action.activityType, selectedActivityName: action.activityName })
  ),
  on(ActivityActions.setActivity,
    (state, action) => ({ ...state, selectedId: action.selectedId })
  ),
  on(ActivityActions.setActivityType,
    (state, action) => ({ ...state, selectedActivityType: action.selectedActivityType })
  ),
  on(ActivityActions.upsertActivity,
    (state, action) => adapterActivity.upsertOne(action.activity, state)
  ),
  on(ActivityActions.addActivities,
    (state, action) => adapterActivity.addMany(action.activities, state)
  ),
  on(ActivityActions.upsertActivities,
    (state, action) => adapterActivity.upsertMany(action.activities, state)
  ),
  on(ActivityActions.updateActivity,
    (state, action) => adapterActivity.updateOne(action.activity, state)
  ),
  on(ActivityActions.updateActivities,
    (state, action) => adapterActivity.updateMany(action.activities, state)
  ),
  on(ActivityActions.deleteActivity,
    (state, action) => adapterActivity.removeOne(action.id, state)
  ),
  on(ActivityActions.deleteActivities,
    (state, action) => adapterActivity.removeMany(action.ids, state)
  ),
  on(ActivityActions.loadActivitiesSuccess,
    (state, action) => adapterActivity.setAll(action.activities, state)
  ),
  on(ActivityActions.activityFail,
    (state, action) => ({
      ...state,
      method: action?.method,
      error: action?.error
    })
  ),
  on(ActivityActions.loadActivityTypesSuccess,
    (state, action) => ({
      ...state,
      activityTypes: action?.activityTypes || null
    })
  ),
  on(ActivityActions.activityTypesFail,
    (state) => ({
      ...state,
      activityTypes: null
    })
  ),
  on(ActivityActions.clearActivities,
    () => initialStateActivity
  ),
  on(ActivityActions.clearSelectedActivity,
    state => ({ ...state, selectedId: null })
  ),

  on(ActivityActions.setIsLoadingActivity,
    (state, action) => ({
      ...state,
      isLoading: action?.isLoading
    })),

  on(ActivityActions.setActivityMode,
    (state, action) => ({ ...state, selectedActivityMode: action.selectedActivityMode })
  ),
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapterActivity.getSelectors();


export const getSelectedId = (state: IActivityState) => state.selectedId;
export const getSelectedActivityMode = (state: IActivityState) => state.selectedActivityMode;

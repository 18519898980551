import { LogService, IPatient } from '@medlogic/shared/shared-interfaces';
import { IFlashObj } from '../../../assist/interfaces/iflash-obj';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { LocalNavigationService } from '../../../module/core/service/local-navigation.service';
import { GlobalService, EnTheme, UnsubscribeOnDestroyAdapter } from '@medlogic/shared/shared-interfaces';
import { ConfigPwaMedLogicService } from '../../../pwa/service/config-pwa-medlogic.custom.service';

@Component({
  selector: 'app-generic-activity',
  templateUrl: './generic-activity.component.html',
  styleUrls: ['./generic-activity.component.css']
})
export class GenericActivityComponent extends UnsubscribeOnDestroyAdapter implements OnInit {


  defaultFormControls: any; // Armazena os valores para preenchimento de um lançamento baseado em recorrência
  atividadeNo: number;
  ocorrenciaNo: number;
  enTheme = EnTheme.default;
  processoNo: number;
  isRecurrent = false;
  flashObjs: IFlashObj[];
  description = 'Atividade';
  isEdit = true;
  genericActivityNo: number;

  public get isMobile(): boolean {
    return this.global.isMobile();
  }

  constructor(
    private log: LogService,
    private cnf: ConfigPwaMedLogicService,
    private route: ActivatedRoute,
    private global: GlobalService,
    private nav: LocalNavigationService) {
    super();
  }

  ngOnInit() {
    try {
      this.nav.addToHistory(this.route.snapshot.url.map(m => m.path), '[activity] GenericActivity');
      this.getUrlParams(this.route);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  /* Carrega os dados. */
  refresh(genericActivityNo: number): void {
    try {
      // Carregamento da Atividade
      this.ocorrenciaNo = -1; // Nova ocorrência
      this.atividadeNo = genericActivityNo;
      this.defaultFormControls = this.getDefaultFormControls(
        this.cnf.OcorrenciaNo.value,
        this.cnf.selectedPatient,
        this.cnf.usuarioLogadoNo,
        this.cnf.usuarioLogadoNome
      );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'refresh', error.message);
    }
  }

  /* Injeta os valores de variáveis, auto preenchendo o formulário. */
  getDefaultFormControls(ono: number, patient: IPatient, professionalNo: number, professionalName: string): any {
    try {
      const dfc = {
        V_28626: ono,
        V_2230: patient.codigo,
        V_387: patient.nomeHospede,
        V_391: patient.nascimento,
        V_101593: new Date(),
        V_29977: professionalName,
        V_28841: professionalNo,
        // 'V_34202': patient.nomeHospede
      };
      return dfc;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getDefaultFormControls', error.message);
    }
  }

  /* Extrai os parâmetros passados na url/rota */
  protected getUrlParams(route: ActivatedRoute): void {
    try { // Os parametros estão sendo passados diretamente aos componentes
      // Para aguardar o carregamento do config
      this.subs.sink = route.params
        .subscribe((params: Params) => {
          if (params.key) {
            this.genericActivityNo = +params.key;
          }
          this.refresh(this.genericActivityNo);
        });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getUrlParams', error.message);
    }
  }


  /* Permitirá que os controles internos chamem uma rotina de recarregamento e limpeza do cache.
  * É útil, por exemplo, ao cadastrar um grid de rateio, forçar o recarregamento.
  * mas essa função não faz retornar para o Grid, apenas prepara para quando o usuário retornar esteja correto.
  */
  onRefresh(args: any): void {
    try {
      this.cacheCleanAndRefresh();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onRefresh', error.message);
    }
  }

  /* Limpa o cache de dados e recarrega. */
  protected cacheCleanAndRefresh(): void {
    try {
      this.refresh(this.cnf.entryCadastroNo);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'cacheClean', error.message);
    }
  }

  onBack(args: any): void {
    try {
      // this.nav.goBack();
      this.nav.gotoRoot();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onBack', error.message);
    }
  }

  onGeBack(args: any): void {
    try {
      this.nav.goBack();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onBack', error.message);
    }
  }

  eventBubble($event: any): void {
    try {

    } catch (error) {
      this.log.Registrar(this.constructor.name, 'eventBubble', error.message);
    }
  }

  afterCompleted($event: any): void {
    try {

    } catch (error) {
      this.log.Registrar(this.constructor.name, 'afterCompleted', error.message);
    }
  }

  afterSaved($event: any): void {
    try {

    } catch (error) {
      this.log.Registrar(this.constructor.name, 'afterSaved', error.message);
    }
  }


}

import { IUtilsEmailState } from '@medlogic/shared/shared-interfaces';
import { createSelector, createFeatureSelector } from '@ngrx/store';
import * as fromEmails from './state-utils-email.reducer';

export const isEmailsLoading = createSelector(
  fromEmails.selectAll,
  state => !!state,
);

export const selectEmailsState = createFeatureSelector<IUtilsEmailState>(fromEmails.utilsEmailFeatureKey);
export const selectEmailsAlls = createSelector(selectEmailsState, fromEmails.selectAll);

import { IActionEmitter, EnActionType, LogService } from '@medlogic/shared/shared-interfaces';
import { Component, Input, OnInit, EventEmitter, Output, OnDestroy } from '@angular/core';
import { FhirActivityService } from '@medlogic/fhir';
import { Subscribable, Subscription, firstValueFrom, take, tap } from 'rxjs';

@Component({
  selector: 'ml-ui-care-plan-footer',
  templateUrl: './ui-care-plan-footer.component.html',
  styleUrls: ['./ui-care-plan-footer.component.css']
})
export class UiCarePlanFooterComponent implements OnInit, OnDestroy {

  @Input() showAdd: boolean = false;
  @Input() showConfirm: boolean = false;
  @Output() actionClick = new EventEmitter<IActionEmitter>();

  ENACTIONTYPE = EnActionType;

  subscribable: Subscription;

  constructor(
    private log: LogService,
    private fhir: FhirActivityService) { }

  async ngOnInit() {
    this.subscribable = this.fhir.getApiVersion().pipe(
      tap((version) => console.log("apiVersion: ", version))).subscribe();
  }

  ngOnDestroy(): void {
    this.subscribable.unsubscribe();
  }

  onActionClick(actionType: EnActionType, data?: any): void {
    try {
      this.actionClick.emit({ actionType, data });
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, 'onActionClick', error.message);
    }
  }
}

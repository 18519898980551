import { EnActivityType, IProgress } from '@medlogic/shared/shared-interfaces';
import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';


export const setProgress = createAction(
  '[Progress/API] Set Progress',
  props<{ selectedId: number }>()
);

export const loadProgresses = createAction(
  '[Progress/API] Load Progress'
);

export const loadProgressSuccess = createAction(
  '[Progress/API] Load Progress Success',
  props<{ progress: IProgress }>()
  );

export const loadProgressesSuccess = createAction(
  '[Progress/API] Load Progresses Success',
  props<{ progresses: IProgress[] }>()
);

export const progressFail = createAction(
  '[Progress/API] Progress Fail',
  props<{ method: string, error: string }>()
);

export const addProgress = createAction(
  '[Progress/API] Add Progress',
  props<{ progress: IProgress }>()
);

export const addProgresses = createAction(
  '[Progress/API] Add Progresses',
  props<{ progresses: IProgress[] }>()
);

export const upsertProgress = createAction(
  '[Progress/API] Upsert Progress',
  props<{ progress: IProgress }>()
);

export const upsertUnscheduledProgress = createAction(
  '[Progress/API] Upsert Unscheduled Progress',
  props<{ progress: IProgress, activityType: EnActivityType}>()
);

export const upsertProgresses = createAction(
  '[Progress/API] Upsert Progresses',
  props<{ progresses: IProgress[] }>()
);

export const updateProgress = createAction(
  '[Progress/API] Update Progress',
  props<{ progress: Update<IProgress> }>()
);

export const updateProgresses = createAction(
  '[Progress/API] Update Progresses',
  props<{ progresses: Update<IProgress>[] }>()
);

export const deleteProgress = createAction(
  '[Progress/API] Delete Progress',
  props<{ id: string }>()
);

export const deleteProgresses = createAction(
  '[Progress/API] Delete Progresses',
  props<{ ids: string[] }>()
);

export const clearProgresses = createAction(
  '[Progress/API] Clear Progresses'
);
export const clearSelectedProgress = createAction(
  '[Card Progress] Clear Selected Progresses'
);

export const setIsLoadingProgress = createAction(
  '[Progress/API] Set isLoadingProgress',
  props<{ isLoading: boolean }>()
);

import { clearPerson } from '@medlogic/medlogic/medlogic-state';
import { clearTenant } from '@medlogic/medlogic/medlogic-state';
import { clearPatients, loadApp, setIsLoading } from '@medlogic/medlogic/medlogic-state';
import { LogService, EnViewMode, UnsubscribeOnDestroyAdapter } from '@medlogic/shared/shared-interfaces';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocalMsgPtBR } from '../../service/local-msg-ptBR.service';
import { AppMedlogicPwaCuidadoState } from '../../ngrx/states/app-state';
import { select, Store } from '@ngrx/store';
import { clearLogin, doBiometricLoginAndLoadRoot, doLoginAndLoadRoot } from '../../ngrx/actions/login.actions';
import { LocalLoginService } from '../../service/local-login.service';
import { ActivatedRoute } from '@angular/router';
import { EnLoginState } from '@medlogic/shared/shared-interfaces';
import { ConfigPwaMedLogicService } from '../../../../pwa/service/config-pwa-medlogic.custom.service';
import { LocalNavigationService } from '../../service/local-navigation.service';
import { from, Observable } from 'rxjs';
import { selectEnLoginState, selectIsLoading, selectUserName } from '../../ngrx/selectors/login.selectors';
import { catchError, first, mergeMap, tap } from 'rxjs/operators';
import { Screenfull } from 'screenfull';
import * as screenfull from 'screenfull';
import { FhirActivityService } from '@medlogic/fhir';

@Component({
  selector: 'ml-local-login-view',
  templateUrl: './local-login-view.component.html',
  styleUrls: ['./local-login-view.component.css']
})
export class LocalLoginViewComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  msgs: any = [];
  isLoading$: Observable<boolean> = this.store.pipe(select(selectIsLoading));
  loginState$: Observable<EnLoginState> = this.store.pipe(select(selectEnLoginState));
  userName$: Observable<string> = this.store.pipe(select(selectUserName));
  login: string;
  password: string;
  showPassword: boolean = false;

  public get version(): string {
    return '5.0.14.2';
  }

  constructor(
    private log: LogService,
    private cnf: ConfigPwaMedLogicService,
    private loginSrv: LocalLoginService,
    private nav: LocalNavigationService,
    private msg: LocalMsgPtBR,
    private snackBar: MatSnackBar,
    private store: Store<AppMedlogicPwaCuidadoState>,
    private route: ActivatedRoute,
    private glb: GlobalService,
    private fhir: FhirActivityService,
  ) {
    super();
  }

  ngOnInit() {
    try {
      this.nav.addToHistory(this.route.snapshot.url.map(m => m.path), '[login] LocalLoginView');
      this.cnf.showMenu = false;
      this.store.dispatch(loadApp({ title: 'Login', isLoading: false, enViewMode: EnViewMode.fullscreen }));
      // TODO: na verdade, precisava limpar todos os caches.
      this.store.dispatch(clearTenant());
      this.store.dispatch(clearPatients());
      this.store.dispatch(clearPerson());
      this.subs.sink = this.userName$.pipe(first(), tap(userName => this.login = userName)).subscribe();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  /* Vai para a página de início do projeto, uma vez logado.
  * Também força o fullscreen caso disponível.
  */
  protected goToRoot(tenantId: number): void {
    try {
      this.toogleFullscreen();
      //screen.orientation.lock('landscape');
      console.log("orientation lock landscape");
      this.cnf.showMenu = true;
      this.nav.gotoRoot();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'goToRoot', error.message);
    }
  }

  /* Ativa/desativa fullscreen se estiver disponível.
  * Por motivo de segurança do navegador, deve ser chamado a partir de um evento do usuário.
  */
  protected toogleFullscreen(forceEnable: boolean = true): void {
    try {
      const sf = screenfull as Screenfull;
      if (sf.isEnabled && ((forceEnable && !sf.isFullscreen) || !forceEnable)) {
        sf.toggle();
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'toogleFullscreen', error.message);
    }
  }

  /* Muda o foco para outro elemento. */
  setFocus(newElement: any): void {
    try {
      newElement.focus();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'setFocus', error.message);
    }
  }

  /* Entrar com o mesmo usuário já logado.
  * Se houver problema no token ou configuração, exibe tela de login e mensagem de falha.
  */
  doEnter($event: any, loginState: EnLoginState): void {
    try {
      if (loginState === EnLoginState.Logged) {
        this.goToRoot(this.cnf.tenantId);
      } else {
        this.logoff();
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'doEnter', error.message);
    }
  }

  /* Botão para mudar o usuário. */
  doChangeUser($event: any): void {
    try {
      this.logoff();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'doEnter', error.message);
    }
  }

  /* Faz o login, baseado em usuário em senha.
  * O token do config será gravado durante a chamada de getLogin.
  * Retornará true se login positivo.
  */
  onConfirm(userName: string, password: string): void {
    try {
      if (this.glb.isNullOrEmpty(userName) || this.glb.isNullOrEmpty(password)) {
        this.onLoginFail();
        return;
      }
      // Para garantir que o login seja feito após o logoff.
      this.store.dispatch(setIsLoading({ isLoading: true }));
      // this.toogleFullscreen();
      this.store.dispatch(doLoginAndLoadRoot({ userName, password }));
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onConfirm', error.message);
    }
  }

  parseChallengeStringToUint8Array = (challenge: string) => Uint8Array.from(
    challenge, c => c.charCodeAt(0))

  base64URLStringToBuffer(base64URLString: string): ArrayBuffer {
    // Convert from Base64URL to Base64
    const base64 = base64URLString?.replace(/-/g, '+')?.replace(/_/g, '/');
    /**
     * Pad with '=' until it's a multiple of four
     * (4 - (85 % 4 = 1) = 3) % 4 = 3 padding
     * (4 - (86 % 4 = 2) = 2) % 4 = 2 padding
     * (4 - (87 % 4 = 3) = 1) % 4 = 1 padding
     * (4 - (88 % 4 = 0) = 4) % 4 = 0 padding
     */
    const padLength = (4 - (base64.length % 4)) % 4;
    const padded = base64.padEnd(base64.length + padLength, '=');

    // Convert to a binary string
    const binary = btoa(padded);

    // Convert binary string to buffer
    const buffer = new ArrayBuffer(binary.length);
    const bytes = new Uint8Array(buffer);

    for (let i = 0; i < binary.length; i++) {
      bytes[i] = binary.charCodeAt(i);
    }

    return buffer;
  }

  onBiometricRegisterConfirm(userName: string): void {

    try {

      if (!window.PublicKeyCredential) {
        alert("Client not capable of this type of credential");
        return;
      }

      this.subs.sink = this.loginSrv.getPublicKey(userName).pipe(
        mergeMap((registrationOptions) => {
          const { publicKey } = registrationOptions;
          publicKey.challenge = this.parseChallengeStringToUint8Array(publicKey.challenge as any);
          publicKey.user.id = this.parseChallengeStringToUint8Array(publicKey.user.id as any);
          console.log(publicKey.excludeCredentials);
          publicKey.excludeCredentials = publicKey.excludeCredentials.map(a => ({ ...a, id: this.base64URLStringToBuffer(a.id as unknown as string) }));

          console.log(publicKey.excludeCredentials);

          return from(navigator.credentials.create(registrationOptions));



        }),
        tap(a => console.log(a)),
        mergeMap(credentials => this.loginSrv.postCredentials(userName, credentials)),
        catchError(error => {
          console.log(error);
          throw new Error(error);
        })
      ).subscribe(({ verified }: any) => verified ? alert("biometria cadastrada com sucesso") : console.log(verified));
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, 'onBiometricRegisterConfirm', error.message);
    }
    // return null;
  }

  onBiometricConfirm(userName: string): void {
    try {
      if (this.glb.isNullOrEmpty(userName)) {
        this.onLoginFail();
        return;
      }
      const publicKey: PublicKeyCredentialCreationOptions = {
        rp: { name: "medlogic" },
        challenge: {} as BufferSource,
        pubKeyCredParams: [{ alg: 1, type: "public-key" }],
        user: {
          id: {} as BufferSource,
          displayName: userName,
          name: userName,
        }
      }
      navigator.credentials.create({ publicKey } as CredentialCreationOptions)
      if (true) {
        this.onLoginFail();
        return;
      }


    } catch (error: any) {
      this.log.Registrar(this.constructor.name, 'onBiometricConfirm', error.message);
    }
    // return null;
  }

  /* Exibe a mensagem de erro no caso de falha. */
  protected onLoginFail() {
    try {
      this.store.dispatch(setIsLoading({ isLoading: false }));
      this.msgs.push({ severity: 'error', summary: this.msg.LOGIN_FAIL_TITLE, detail: this.msg.LOGIN_FAIL });
      setTimeout(() => this.msgs = [], 2000);
      this.snackBar.open(this.msg.LOGIN_FAIL, '', {
        duration: 2000,
      });
      this.logoff();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onLoginFail', error.message);
    }
  }

  /** Call all necessary methods to clean the user session, cookie and tenant.  */
  logoff(isLoading: boolean = false): void {
    try {
      this.store.dispatch(clearLogin());
      this.loginSrv.logoff(isLoading);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'logoff', error.message);
    }
  }

  visibilityPassword(): void {
    try {
      this.showPassword = !this.showPassword;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'visibilityPassword', error.message);
    }
  }


}

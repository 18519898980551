
import { NgModule } from '@angular/core';
import { UnsubscribeOnDestroyAdapter } from './service/unsubscribe-ondestroy.adapter';
import { MsgPtBR } from './service/msg-ptBR.service';
import { LocalLibService } from './service/lib.service';
import { LogService } from './service/log.service';
import { GlobalService } from './service/global.service';
import { ConfigJsonService } from './service/config-json.service';

const SERVICES = [
  LogService,
  LocalLibService,
  GlobalService,
  ConfigJsonService,
  MsgPtBR,
  UnsubscribeOnDestroyAdapter,
];

@NgModule({
  imports: [ ],
  exports: [ ],
  providers: [
    SERVICES,
  ],
})
export class SharedInterfacesNgModule { }

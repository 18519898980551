import { IPatientTimeline } from "../../../assist/interfaces/ipatient-timeline";
import { LogService, IPatient } from '@medlogic/shared/shared-interfaces';
import { ActivatedRoute } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { EnCareType } from "../../../assist/enum/en-care-type.enum";
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { ConfigPwaMedLogicService } from '../../../pwa/service/config-pwa-medlogic.custom.service';

@Component({
  selector: "app-patient-timeline",
  templateUrl: "./patient-timeline.component.html",
  styleUrls: ["./patient-timeline.component.css"],
})
export class PatientTimelineComponent implements OnInit {
  patient: IPatient;
  items: IPatientTimeline[] = [];

  constructor(
    private log: LogService,
    private route: ActivatedRoute,
    private location: Location,
    private cnf: ConfigPwaMedLogicService,
    private glb: GlobalService
  ) { }

  ngOnInit() {
    try {
      this.refresh(this.cnf.selectedPatient);
    } catch (error) {
      this.log.Registrar(this.constructor.name, "ngOnInit", error.message);
    }
  }
  /* Retorna a idade baseado na data de nascimento. */
  getAge(birthDate: Date): number {
    try {
      if (birthDate !== undefined) {
        return this.glb.GetYearsOld(birthDate);
      } else {
        return 0;
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, "getAge", error.message);
    }
  }
  /* Resgata os parâmetros de URL. */
  // protected getParams(): void {
  //   try {
  //     this.route.data.subscribe((p => {
  //       console.log("param", p);
  //       this.refresh(data);
  //     });
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'getParams', error.message);
  //   }
  // }

  protected refresh(patient: IPatient): void {
    try {
      this.patient = patient;
      this.items = this.getItems(patient);
    } catch (error) {
      this.log.Registrar(this.constructor.name, "refresh", error.message);
    }
  }

  getInterval(date: Date): string {
    const dif = this.glb.dateDiff(date, new Date(), "t");
    return dif;
  }

  protected getItems(patient: IPatient): IPatientTimeline[] {
    try {
      return [
        {
          time: "31/07/2017 08:40",
          title: "Queixa de dores abdominais",
          // tslint:disable-next-line: max-line-length
          description:
            "O paciente se queixou de fortes dores abdominais durante toda a noite. Não chamou a cuidadora, mas agora a dor está insuportável",
          enCareType: EnCareType.intercorrencia,
          // imgUrl: "http://fakeimg.pl/150x150/",
          date: new Date(2017, 6, 31, 19, 40),
        } as IPatientTimeline,
        {
          time: "02/08/2017 10:40",
          title: "Melhora na deglutição",
          description:
            "O paciente conseguiu se alimentar sem auxílio da cuidadora!",
          enCareType: EnCareType.progresso,
          imgUrl: "http://fakeimg.pl/150x150/",
          date: new Date(2017, 6, 31, 19, 40),
        } as IPatientTimeline,
        {
          time: "02/08/2017 10:40",
          title: "Queixa de dor de cabeça",
          description:
            "Foi ministrada uma aspirina 10 mg para atenuar a dor. A queixa sessou 30 minutos após o comprimido.",
          enCareType: EnCareType.conduta,
          // imgUrl: "http://fakeimg.pl/150x150/",
          date: new Date(2017, 6, 31, 19, 40),
        } as IPatientTimeline,
      ];
    } catch (error) {
      this.log.Registrar(this.constructor.name, "refresh", error.message);
    }
  }

  /* Voltar */
  onBack(): void {
    try {
      this.location.back();
    } catch (error) {
      this.log.Registrar(this.constructor.name, "refresh", error.message);
    }
  }


}

import { EstoqueMateriaisCustomServices } from '@medlogic/medlogic/medlogic-data-access';
import { ConfigPwaMedLogicService } from './../../../pwa/service/config-pwa-medlogic.custom.service';
import { ModelComponent } from '@medlogic/shared/shared-data-access';
import { ActivatedRoute } from '@angular/router';
// import { Message } from 'primeng';
import { NavigationPwaService } from '../../../pwa/service/navigation-pwa.service';
import { IInvoice } from '../../../assist/interfaces/iinvoice';
import { Component, OnInit, ViewChildren, ViewChild } from '@angular/core';
import {
  HomeView,
  MaterialDialogService, CustomerConfigService
} from '@medlogic/shared/gecore';
import {
  GlobalService,
  ICadParam,
  LogService
} from '@medlogic/shared/shared-interfaces';
import { IEstoqueMateriais } from '@medlogic/shared/shared-interfaces';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocalMsgPtBR } from '../../../module/core/service/local-msg-ptBR.service';
import { of, forkJoin } from 'rxjs';
import { filter, map, toArray } from 'rxjs/operators';

@Component({
  selector: 'ml-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.css']
})
export class InventoryViewComponent extends HomeView implements OnInit {

  FORNECEDOR = 'Saída Cozinha';
  UNIDADE_NEGOCIO = 'Administrativo';

  displayedColumns: string[] = ['itens', 'estoque', 'unidademedida', 'uso', 'estoqueRemanescente'];

  @ViewChildren('slider') sliders: any[];

  private _date = new Date();
  public get date(): Date {
    return this._date;
  }
  public set date(v: Date) {
    this._date = v;
  }

  lstConfig: any[] = [];
  modeloConfigNo = -1;
  // msgs: Message[] = []; // Se preenchido, aciona o growl
  itemEstoque = {} as IEstoqueMateriais; // armazena a medicação selecionada
  selectedMonth: any = 0;
  today: string;
  currentTime: Date = new Date();
  isLoading = true;

  estoqueMateriais = Array<IEstoqueMateriais>();

  cadEstoqueMateriaisNo = -1;
  mask: (string | RegExp)[] = [/\d*/];
  @ViewChild('modalDialog', { static: true }) modalDialog;

  constructor(
    log: LogService,
    glb: GlobalService,
    protected cnf: ConfigPwaMedLogicService,
    route: ActivatedRoute,
    modelComponent: ModelComponent,
    protected nav: NavigationPwaService,
    private matDialog: MaterialDialogService,
    private stockSrv: EstoqueMateriaisCustomServices,
    private snackBar: MatSnackBar,
    private custCnf: CustomerConfigService,
    protected msg: LocalMsgPtBR,
  ) {
    super(log, glb, cnf, route, nav, modelComponent, custCnf, msg);
    try {

    } catch (error) {
      this.log.Registrar(this.constructor.name, 'constructor', error.message);
    }
  }

  ngOnInit() {
    try {
      setTimeout(() => { // Necessário para que não haja mudança da variável
        // durante o processo de carregamento ExpressionChangedAfterItHasBeenCheckedError
        this.isLoading = true;
        this.subs.sink = this.getCadParams(this.route)
          .subscribe(params => {
            try {
              this.cadEstoqueMateriaisNo = this.getParam('cadEstoqueMateriaisNo', params);
              this.refresh();
            } catch (error) {
              this.log.Registrar(this.constructor.name, 'ngOnInit.subscribe', error.message);
            }
          });
      });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  /* Define os parâmetros extraídos do Cadastro de Configurações. */
  protected setCadParams(): void {
    try {
      this.cadParams = [
        { idName: 'cadEstoqueMateriaisNo', label: 'Cad_Estoque_Materiais' } as ICadParam,
      ];
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'setCadParams', error.message);
    }
  }

  protected setCurrentRoute(): void {
    try {
      this.currentRoute = this.nav.getRouteCardMedication2(this.cnf.modeloConfigNo, this.cadEstoqueMateriaisNo);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'setCurrentRoute', error.message);
    }
  }

  /* Carrega os campos na tela.  */
  protected refresh(): void {
    try {
      const dt = new Date();
      this.today = `${this.glb.DiaDaSemana(dt.getDay())}, ${dt.getDate()} de ${this.glb.NomeDoMes(dt)} de ${dt.getFullYear()}`;
      this.setTimer();
      this.loadStock(this.cadEstoqueMateriaisNo);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'refresh', error.message);
    }
  }

  /* Carrega o Estoque de Materiais. */
  protected loadStock(stockCadastroNo: number): void {
    try {
      const dtStart = new Date(new Date().getFullYear(), 0, 1);
      const dtEnd = this.glb.addMonthsUTC(new Date(), 3);
      // Registros
      const observableStock =
        this
          .stockSrv
          .getAll(stockCadastroNo, dtStart, dtEnd)
          .pipe(
            filter(f => this.glb.isEqual(f.tipoMaterial, this.cnf.kitchenCategoryName)),
            map((m: any) => { // Acrescenta a propriedade usada para armazenar a entrada do usuário
              m.estoque = this.glb.ConvertToAmericanNumber(m.estoque);
              m.uso = 0;
              m.estoqueRemanescente = m.estoque;
              return m;
            })
          );
      this.subs.sink = observableStock.pipe(
        toArray())
        .subscribe(s => {
          this.estoqueMateriais = s;
        });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'loadStock', error.message);
    }
  }

  /* Usuário confirma a ministração da medicação. */
  // confirmMedication(item: { medication: IMedication, patient: IPatient }): void {
  //   try {
  //     let checkin = this.mapToIMedicationCheckin(item.medication, item.patient);
  //     this.medicationCheckinSrv
  //       .save(this.cadCheckMedicamentosNo, checkin, item.patient, item.medication)
  //       .subscribe(s => s,
  //         (e) => { // erro
  //           console.log(e);
  //           this.isLoading = false;
  //           alert("Erro ao salvar checkin!");
  //         },
  //         () => { // complete
  //           this.modalDialog.displayDialog = false;
  // this.msgs.push({ severity: 'success', summary: 'Confirmação', detail: 'Medicação registrada com sucesso!' });
  //           this.snackBar.open(this.msg.MEDICATION_SUCCESS, null, {
  //             duration: 2000,
  //             panelClass: 'snackbar'
  //           });
  //           item.medication.took = true;
  //           this.isLoading = false;
  //         });
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'confirmMedication', error.message);
  //   }
  // }

  /* Mapeio para o objeto necessário para salvar. */
  // mapToIMedicationCheckin(medication: IMedication, patient: IPatient): IMedicationCheckin {
  //   try {
  //     // Atenção: para compor o ID é necessário considerar a data e hora prescrita e não do checkin, senão não será possível correlacionar corretamente como o item prescrito.
  //     const datePrescricao = moment(medication.medicationDate).format("DD/MM/YYYY");
  //     const horaPrescricao = medication.prescribedTime;
  //     const horaCheckin = moment(medication.medicationDate).format("hh:mm");
  //     const titulo = this.medicationCheckinSrv.generateMedicationTitle(patient, medication);
  //     const key = this.medicationCheckinSrv.generateKey(patient.codigo, medication);
  //     const medicationCheckin = <IMedicationCheckin>{
  //       cascataCheckIDClienteIDMedicamentoDataAtual: key,
  //       identificacao: key,
  //       horaPrescricao: horaPrescricao,
  //       diaPrescricao: datePrescricao,
  //       dataCheckin: medication.medicationDate,
  //       horaCheckin: horaCheckin,
  //       status: true,//O texto Tomou, que é necessário para que o campo seja considerado true, é redefinido na imedication-checkin
  //       observacoes: "",
  //       statusmedicacao: titulo,
  //       // codigo: -1, O código não pode ser fornecido para que seja calculado automaticamente no serviço
  //       titulo: titulo,
  //       habilitado: true,
  //       codigoMedicamentoPaciente: medication.medicationId + "_" + patient.nomeHospede,
  //       profissional: this.cnf.usuarioLogadoNome,
  //       codigoPaciente: patient.codigo,
  //       Id_Paciente: patient.codigo,
  //       Id_Medicamento: medication.medicationId.toString(),
  //       IdMedicamento_IdPaciente: `${medication.medicationId}_${patient.codigo}`
  //     }
  //     // console.log("medication: ", medication, "patient: ", patient);
  //     return medicationCheckin;
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'formatObjSave', error.message);
  //     return null;
  //   }
  // }

  /* Usuário desiste de registrar a medicação. */
  cancelMedication(): void {
    try {
      this.modalDialog.displayDialog = false;
      // this.msgs.push({ severity: 'error', summary: 'Cancelamento', detail: 'Registro cancelado pelo usuário.' });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'cancelMedication', error.message);
    }
  }

  /* Ativa o relógio */
  protected setTimer(): void {
    try {
      setInterval(() => {
        this.currentTime = new Date();
      }, 1000);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'setTimer', error.message);
    }
  }

  /* Irá marcar as medicações que estiverem pendentes, no cache.
    * // FIXME: Atenção: a variável 30264 está fixa no código.
    */
  // protected checkMedicationThatIsPending(medications: Array<IMedication>, currentDate: Date): void {
  //   try {
  //     this.cadastroSrv
  //       .getItemsFromQueue()
  //       .subscribe(items => {
  //         try {
  //           if (items && medications && medications.length > 0) {
  //             medications.forEach(medication => {
  //               try {
  //                 const find = items.filter(f => {
  //                   return (<ICadastro>f.cadastro).LstItensCadastro.find(d => {
  //                     return this.glb.isEqual(d.VariavelNo, 30264) && this.isTheMedication(d.ValorDado, medication, currentDate);
  //                   });
  //                 });
  //                 medication.took = medication.took || (find.length > 0);
  //               } catch (error) {
  //                 this.log.Registrar(this.constructor.name, 'checkMedicationThatIsPending.forEach', error.message);
  //               }
  //             });
  //           }
  //         } catch (error) {
  //           this.log.Registrar(this.constructor.name, 'checkMedicationThatIsPending.subscribe', error.message);
  //         }
  //       });
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'checkMedicationThatIsPending', error.message);
  //   }
  // }

  /* Evento Change do slider da quantidade utilizada.  */
  onUsoChange(item: any): void {
    try {
      const newValue = parseFloat(item.uso || 0);
      item.uso = newValue;
      item.estoqueRemanescente = parseFloat(item.estoque || 0) - newValue;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onUsoChange', error.message);
    }
  }

  /* Confirmar o lançamento do dia. */
  onConfirmClick(event: any): void {
    try {
      this.matDialog
        // tslint:disable-next-line: max-line-length
        .openDialogAlert(`Itens registrados com sucesso! Em ${this.glb.dateToddMMYYYYhhmmss(new Date())} por ${this.cnf.usuarioLogadoNome}.`);
      this.clean();
      this.updateStock(this.estoqueMateriais, this.date, this.UNIDADE_NEGOCIO, this.FORNECEDOR);
      this.refresh();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onConfirmClick', error.message);
    }
  }

  /* Dá a saída dos itens no estoque. */
  protected updateStock(items: any[], date: Date, bunit: string, supplier: string): void {
    try {
      const observables =
        items
          .filter(f => f.uso > 0)
          .map(m => {
            const invoice = {
              item: m.material,
              itemStock: m.material,
              value: 0,
              unity: m.unidademedida,
              cc: m.centroCusto,
              quantity: -m.uso,
              price: 0,
              billing: false,
              bunit,
              date,
              supplier,
            } as IInvoice;
            // TODO: Falta adequar aos novos parâmetros
            return of(null);
            // return this.stockSrv.insereItemEstoque(invoice, this.cadEstoqueMateriaisNo);
          });
      this.subs.sink = forkJoin(observables)
        .subscribe(
          () => this.showMessage(this.msg.STOCK_UPDATE_KITCHEN_FAIL),
          (e) => console.log(e),
          () => this.showMessage(this.msg.STOCK_UPDATE_KITCHEN_SUCCESS)
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'updateStock', error.message);
    }
  }

  /* Exibe uma mensagem temporizada no rodapé do app. */
  private showMessage(msg: string): void {
    try {
      this.snackBar.open(msg, null, {
        duration: 2000,
        panelClass: 'snackbar'
      });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'showMessage', error.message);
    }
  }

  /* Irá reiniciar a entrada de dados, zerando o uso.  */
  protected clean(): void {
    try {
      this.estoqueMateriais.forEach((f: any) => f.uso = 0);
      this.sliders.forEach(f =>
        f.value = 0
      );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'clean', error.message);
    }
  }


}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  GlobalService, LogService,
  LocalLibService,
  ConfigJsonService,
  UnsubscribeOnDestroyAdapter
} from '@medlogic/shared/shared-interfaces';
import { Observable, of } from 'rxjs';
import { FhirCadastroService } from './fhir-cadastro.service';

@Injectable({ providedIn: 'root' })
export abstract class FhirGenericService extends UnsubscribeOnDestroyAdapter {

  protected abstract readonly url: string;

  constructor(
    public cadastroSrv: FhirCadastroService,
    public glb: GlobalService,
    public cnf: ConfigJsonService,
    public lib: LocalLibService,
    public log: LogService,
    public http: HttpClient
  ) {
    super();
  }

  getOne<T>(id: number): Observable<T | null> {
    try {
      const url = this.getUrl() + `getOne?id=${id}`;
      const $result = this.http.get<T>(url);

      return $result;
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, '', error.message);
    }

    return of(null);
  }

  getAll<T>(): Observable<T | null> {
    try {
      const url = this.getUrl() + 'getAll';
      const $result = this.http.get<T>(url);

      return $result;
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, '', error.message);
    }

    return of(null);
  }

  create<T>(data: T): Observable<T | null> {
    try {
      const url = this.getUrl() + 'post';
      const $result = this.http.post<T>(url, { data });

      return $result;
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, '', error.message);
    }

    return of(null);
  }

  createMany<T>(array: T[]): Observable<T[] | null> {
    throw new Error('Method not implemented.');
  }

  update<T>(data: T): Observable<T | null> {
    try {
      const url = this.getUrl() + 'put';
      const $result = this.http.put<T>(url, { data: data });

      return $result;
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, '', error.message);
    }
    return of(null);
  }

  updateMany<T>(array: T[]): Observable<T[] | null> {
    throw new Error('Method not implemented.');
  }

  delete(id: string): Observable<boolean> {
    try {
      const url = this.getUrl() + `delete?id=${id}}`;
      const $result = this.http.delete<boolean>(url) as Observable<boolean>;

      return $result;
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, '', error.message);
    }
    return of(false);
  }


  protected getUrl(): string {
    if (this.glb.IsNullOrEmpty(this.url)) {
      throw new Error('Url não definida na classe filha');
    }

    return `${this.cnf.baseUrlAPI}${this.url}/`;
  }
}

<div class="container">

  <button type="button" mat-button (click)="onBackClick($event)" class="btn">
    <mat-icon>arrow_back</mat-icon> Voltar</button>

  <ng-container *ngIf="fields$ | async as fields; else loading">
    <ml-ui-care-plan-actitivy-detail [activity]="activity$ | async" [performerPractitioner]="selectedPractitioner$ | async" [practitioners]="practitioners$ | async"
      (updateValues)="onUpdateValues($event)" [activityName]="activityName$ | async" [activityType]="activityType$ | async" (confirm)="onConfirm($event)" [fields]="fields"></ml-ui-care-plan-actitivy-detail>
  </ng-container>
  
  <ng-template #loading>
    <div class="loading-spinner">
      <mat-spinner></mat-spinner>
    </div>
  </ng-template>

</div>

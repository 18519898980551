import { ConfigStateService } from '@medlogic/shared/state-config';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { CustomerConfigService, HomeView } from '@medlogic/shared/gecore';
import { take, tap } from 'rxjs/operators';
import { LocalNavigationService } from '../../service/local-navigation.service';
import { AppProvider } from '../../service/app.provider';
import { IListItem } from '../../interface/ilist-item';
import { selectGeneric, loadGeneric } from '../../ngrx/actions/generic.actions';
import { selectGenericById, selectAllGenericsAsListItemsByKeyword } from '../../ngrx/selectors/generic.selectors';
import { GenericCustomService } from '../../service/generic.custom.service';
import { Store, select } from '@ngrx/store';
import { AppMedlogicPwaCuidadoState } from '../../ngrx/states/app-state';
import { NgRxNavigationService } from '../../service/ngrx-navigation.service';
import { GlobalService, IModule, EnTheme, ITenant, LogService } from '@medlogic/shared/shared-interfaces';
import { ModelComponent } from '@medlogic/shared/shared-data-access';
import { LocalMsgPtBR } from '../../service/local-msg-ptBR.service';

@Component({
  selector: 'app-generic-list-view',
  templateUrl: './generic-list-view.component.html',
  styleUrls: ['./generic-list-view.component.css']
})
export class GenericListViewComponent extends HomeView implements OnInit {

  enTheme = EnTheme.black;
  readonly PAGE_TITLE = 'Generic List';
  readonly NUM_MIN_OF_CHAR_SEARCH = 3;
  isLoading = true;
  search = '';
  items$: Observable<IListItem[]> = this.store.select(state => selectAllGenericsAsListItemsByKeyword(state, { keyword: this.search }));
  tenant: ITenant;
  process: IModule;
  readOnly = false;
  genericId: number;
  title = '';

  constructor(
    log: LogService,
    glb: GlobalService,
    cnf: ConfigStateService,
    route: ActivatedRoute,
    nav: LocalNavigationService,
    modelComponent: ModelComponent,
    customerCnf: CustomerConfigService,
    msg: LocalMsgPtBR,
    matDialog: MatDialog,
    protected appPrv: AppProvider,
    private navigationService: NgRxNavigationService,
    private store: Store<AppMedlogicPwaCuidadoState>,
    protected genericSrv: GenericCustomService
  ) {
    super(log, glb, cnf, route, nav, modelComponent, customerCnf, msg);
  }

  ngOnInit(): void {
    try {
      this.isLoading = true;
      this.store
        .pipe(
          take(1),
          tap(state => {
            this.tenant = state.tenant.selectedTenant;
            this.process = state.process.selectedProcess;
            this.genericId = this.process.ObjInfo.AtividadeNo;
            this.title = this.process.Label;
            this.store.dispatch(loadGeneric({ genericId: this.genericId }));
          })
        ).subscribe();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  // protected getUrlParam(): Observable<any> {
  //   try {
  //     return this.route.params
  //       .pipe(
  //         tap((params: Params) => {
  //           if (params.load === 'true') {
  //             // TODO: Deve estar em effects, mas a AtividadeNo selecionada precisa chegar.
  //           }
  //         }),
  //         this.error()
  //       );
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'getUrlParam', error.message);
  //   }
  //   return of(null);
  // }

  protected setCadParams(): void {
    try {
      // this.cadParams = [
      //   // { idName: 'entryCadastroNo', label: 'CAD_ENTRADA_FLUXO_DE_CAIXA' } as ICadParam,
      // ];
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'setCadParams', error.message);
    }
  }

  protected setCurrentRoute(title: string = null): void {
    try {
      // this.currentRoute = this.nav2.getRouteGenericHome(this.s);
      // this.addToHistory(title);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'setCurrentRoute', error.message);
    }
  }

  /* Método principal para carregamento. */
  protected refresh(): void {
    try {
      this.subs.sink = this.appPrv.search$.subscribe(s => this.search = s);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'refresh', error.message);
    }
  }

  /* Clique numa pessoa específica.  */
  onItem_click(item: IListItem, genericId: number, process: IModule, enTheme: EnTheme, readOnly: boolean): void {
    try {
      this.navigationService.subscribeToRouteChange();
      this.subs.sink = this.store
        .pipe(
          select(selectGenericById(item.id))
        ).subscribe(selectedGeneric => {
          this.store.dispatch(selectGeneric({ selectedGeneric }));
          // TODO:
          // this.nav.gotoRouteContaActionEdit(genericId, process.ObjInfo.ProcessoNo, selectedGeneric.id, enTheme, readOnly);
        });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onGeneric_click', error.message);
    }
  }

  /* Botão adicionar. */
  onAdd_click(genericId: number, process: IModule, enTheme: EnTheme): void {
    try {
      // this.nav.gotoRouteAccountNew(genericId, process.ObjInfo.ProcessoNo, enTheme);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onAdd_click', error.message);
    }
  }


}

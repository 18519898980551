import { IMedication } from './../../interface/medlogic/imedication';
import { IInterventionMedication } from '../../interface/medlogic/iintervention-medication';
import { IMedicationCheckin } from '../../interface/medlogic/imedication-checkin';
import { IIntervecoesMedicamentosHorarios } from '../../interface/medlogic/iintervencoes-medicamentos-horarios';
import { GlobalService } from '../global.service';
import { EnPosology } from '../../enum/medlogic/en-posology.enum';
import { IForm } from '../../interface/iform';
import { EnTypedValue } from '../../enum/en-typed-value.enum';

import * as moment from 'moment';
import { LogService } from '@medlogic/shared/shared-interfaces';

const glb = new GlobalService();

export const INTERVENTION_LSTVARIAVEIS = 'V_828,V_830,V_832,V_2949,V_2952,V_3530,V_27993,V_29828,V_29834,V_29838,V_29949,V_30212,V_30213,V_30214,V_30263,V_30264,V_30266,V_30270,V_30271,V_30272,V_30296,V_28018,V_30307,V_30308,V_30320,V_30330,V_30339,V_30356,V_30367,V_30401,V_31571,V_32859,V_32860,V_34906,V_104628,V_31967,V_31968,V_30396,V_32857,V_32858,V_32861,V_32862,V_32939,V_35051,V_101103,V_104223,V_32935,V_34709,V_109968';
export const INTERVENTION_VARIAVEL_GRID = 'V_30277';
export const INTERVENTION_LSTVARIAVEIS_GRID = 'V_30264,V_30273,V_30274,V_30275,V_30276,V_30330,V_30331,V_30351,V_30264,V_30352,V_30353,V_30401,V_31927,V_34166';
export const INTERVENTION_VARIAVEL_GRID_HORARIOS = 'V_30396';
export const INTERVENTION_LSTVARIAVEIS_GRID_HORARIOS = 'V_29828,V_30263,V_30392,V_30393,V_30394,V_30395,V_30401';

export const mapInterventionToMedication = (
  intervMed: IInterventionMedication | any,
  prescribedTime: string = null,
  lstCheckin: IMedicationCheckin[] = null,
  lstHorarios: IIntervecoesMedicamentosHorarios[] = null
): IMedication => {
  try {
    if (!intervMed) {
      return null;
    }

    prescribedTime = glb.getHHMM(prescribedTime || intervMed.horaprimeiraDose);
    const checksOfMedication = intervMed?.lstmedicationCheckin ?? intervMed?.lstmedicationCheckin?.filter((f) =>
      glb.isEqual(f.Id_Medicamento, intervMed.Id_Medicamento) &&
      glb.isEqual(glb.getHHMM(f.horaPrescricao), prescribedTime)
    );
    lstCheckin = lstCheckin || checksOfMedication || intervMed.MedicationCheckin;

    return {
      medicationId: +intervMed.Id_Medicamento,
      ocorrenciaNo: +intervMed.ocorrenciaNo,
      medicationName: intervMed.medicametnoPrescrito || intervMed.medicamento?.split('_')[0] || intervMed.ItemEstoque?.name,
      prescription: intervMed.medicametnoPrescrito,
      prescriptionId: intervMed.Id_Prescricao,
      prescribedTime: glb.formatTime(prescribedTime, true),
      dosage: intervMed.dosagem,
      dosageValue: intervMed.dosagemValor || glb.separateValueFromUnity(intervMed.dosagem).value,
      dosageUnity: intervMed.dosagemUnidade || glb.separateValueFromUnity(intervMed.dosagem).unity,
      took: false,
      lstCheckin,
      lstHorarios,
      date: intervMed.dataPRESCRICAO,
      status: null,
      access: intervMed.via?.name || intervMed.via,
      presentation: intervMed.apresentacao || intervMed.Apresentacao?.name,
      instruction: intervMed.orientacoes,
      material: intervMed.tIPOMaterial?.name || intervMed.tIPOMaterial || 'MEDICAMENTO',
      businessUnit: intervMed.uNIDADEDENEGOCIO,
      costCenter: intervMed.centrocusto,
      unity: intervMed.apresentacao || intervMed.Apresentacao?.name,
      codPacienteNomedoPacienteCodMedicamento: intervMed.codPacienteNomedoPacienteCodMedicamento,
      // FIXME: forçando a validade das datas
      dtStart: intervMed.dataInicio,
      dtEnd: intervMed.dataFim,
      enPosology: intervMed.posologia?.name || intervMed.posologia,
      intervaloDS: intervMed.intervaloDS,
      dailyQuantity: intervMed.quantUtilizadaD || 1,
      dailyQuantityGotas: intervMed.quantUtilizadaGota || 1,
      isSOS: intervMed.calc__isSOS,
      medicationDate: intervMed.dataEVOLUCAO,
      stopMedication: intervMed.interroperMedicacao || intervMed.interromperMedicacao || false,
      stopInstructions: intervMed.interrupcaoMotivo,
      isRestricted: glb.getBoolean(intervMed.medicamentoControlado),
      tempoUso: intervMed.tempoUso || intervMed.TempoUso?.name || getTempoUso(intervMed?.tempoUsoId || -1),
      order: +intervMed.ordem,
      wasChanged: intervMed.wasChanged,
      estimatedTime: intervMed.tempoEstimado,
      materialId: intervMed.materialId || intervMed.tIPOMaterial?.tipoMaterialId,
      horarios: intervMed.horarios,
      cor: intervMed.cor,
      patientId: intervMed.Id_Paciente
      // horarios: convertGridHorariosToText(intervMed.lsthorariosGrid)
    } as IMedication;
  } catch (error) {
    // log.Registrar('map-intervention-medication', 'mapInterventionToMedication', error.message);
  }
  return null;
}

// TODO: Não é ideal, pois, engessamos o uso de um objeto para representar o tempo de uso
const getTempoUso = (tempoUsoId: number): string => {
  return tempoUsoId === 1 ? 'Ocasional' : 'Permanente';
}

export const mapMedicationToIntervention = (medication: IMedication, professional: string, patientId: number = null, patientName: string = null): IInterventionMedication => {
  try {
    if (!medication) {
      return null;
    }

    const splitCodPacNomePacCodMed = medication?.codPacienteNomedoPacienteCodMedicamento?.split('_');
    patientId = patientId || (splitCodPacNomePacCodMed ? +splitCodPacNomePacCodMed[0] : null);
    patientName = patientName || (splitCodPacNomePacCodMed ? splitCodPacNomePacCodMed[1] : null);
    const material = medication?.material;
    return {
      apresentacao: medication.presentation,
      calc__isSOS: medication.isSOS,
      // cascataCheckIDClienteIDMedicamentoDataEVOLUCAO: medication.cascataCheckIDClienteIDMedicamentoDataEVOLUCAO,
      centrocusto: medication.costCenter,
      centroCustoIDCentroCusto: `${patientName}_${patientId}`,
      codigo: medication.medicationId,
      codigoHospede: patientId,
      codigomedicamento: medication.medicationId,
      codigoMedicamentoPaciente: `${medication.medicationId}_${patientName}`,
      codigoPaciente: patientId,
      codigoPacienteNomePaciente: `${patientId}_${patientName}`,
      codPacienteNomedoPacienteCodMedicamento: medication.codPacienteNomedoPacienteCodMedicamento,
      dataEVOLUCAO: medication.medicationDate,
      dataFim: medication.dtEnd,
      dataInicio: medication.dtStart,
      dataPRESCRICAO: medication.date,
      // dia: medication.order,
      dosagem: medication.dosage,
      dosagemValor: medication.dosageValue,
      dosagemUnidade: medication.dosageUnity,
      guid: medication.guid,
      habilitado: true,
      // hojedia: null,
      horaprimeiraDose: glb.formatTime(medication.prescribedTime, true),
      horarios: medication.horarios,
      Id_Medicamento: +medication.medicationId,
      Id_Paciente: patientId,
      Id_Prescricao: +medication.prescriptionId,
      IdMedicamento_IdPaciente: `${medication.medicationName}_${patientId}`, // No Studio, a fórmula está para o nome ao invés do id`${medication.medicationId}_${patientId}`
      interroperMedicacao: medication.stopMedication,
      interrupcaoMotivo: medication.stopInstructions,
      intervaloDS: medication.intervaloDS,
      intervaloHoras: medication.intervaloDS,
      lsthorariosGrid: medication.lstHorarios,
      lstmedicationCheckin: medication.lstCheckin,
      medicamento: medication.medicationName,
      medicamentoControlado: glb.getReverseBoolean(medication.isRestricted),
      medicametnoPrescrito: medication.medicationName,
      ocorrenciaNo: +medication.ocorrenciaNo,
      ordem: medication.order,
      orientacoes: medication.instruction,
      posologia: medication.enPosology,
      profissional: professional,
      quantTempoEstimado: glb.isEqual(medication.material, 'TERAPIA') ? medication.estimatedTime : medication.dailyQuantity,
      quantUtilizadaD: medication.dailyQuantity || 1,
      quantUtilizadaGota: medication.dailyQuantityGotas,
      tempoEstimado: medication.estimatedTime,
      tempoUso: medication.tempoUso, // Ocasional? Permanente?
      tIPOMaterial: medication.material,
      tipomedicamento: material,
      tIPOMedicamentos: material,
      tipoMedicamentosCodPaciente: `${medication.materialId}_${patientId}`,
      titulo: medication.medicationTitle || `Paciente: ${patientName}__${medication.medicationName}__${medication.dosage}__${medication.medicationId}`,
      uNIDADEDENEGOCIO: medication.businessUnit,
      validCadastro: new Date(),
      via: medication.access,
      wasChanged: medication.wasChanged,
      materialId: medication.materialId,
      cor: medication.cor,
    } as unknown as IInterventionMedication;
  } catch (error) {
    // log.Registrar('map-intervention-medication', 'mapMedicationToIntervention', error.message);
  }
  return null;
}

export const convertToValorTextoHorarios = (horariosGrid: IIntervecoesMedicamentosHorarios[]): string => {
  try {
    let valorTexto = '<![CDATA[<Items>';
    if (horariosGrid) {
      horariosGrid.forEach(f => {
        valorTexto += `<Item><index>${f.codigo}</index>`;
        if (f.Id_Paciente) valorTexto += `<V_29828>${f.Id_Paciente}</V_29828>`;
        if (f.Id_Medicamento) valorTexto += `<V_30263>${f.Id_Medicamento}</V_30263>`;
        if (f.codigo) valorTexto += `<V_30392>${f.codigo}</V_30392>`;
        if (f.titulo) valorTexto += `<V_30393>${f.titulo}</V_30393>`;
        if (f.habilitado) valorTexto += `<V_30394>${f.habilitado}</V_30394>`;
        if (f.hora) valorTexto += `<V_30395>${f.hora}</V_30395>`;
        if (f.IdMedicamento_IdPaciente) valorTexto += `<V_30401>${f.IdMedicamento_IdPaciente}</V_30401>`;
        valorTexto += '</Item>';
      });
    }
    valorTexto += '</Items>]]>';
    return valorTexto;
  } catch (error) {
    // log.Registrar('map-intervention-medication', 'convertToValorTexto', error.message);
  }
  return '';
}

export const convertGridHorariosToText = (lsthorariosGrid: IIntervecoesMedicamentosHorarios[]): string => {
  try {
    return glb.Distinct(lsthorariosGrid)?.map(m => m.hora).join(', ');
  } catch (error) {
    // log.Registrar('map-intervention-medication', 'convertToValorTexto', error.message);
  }
  return '';
}

export const timesAccordingPosology = (
  intervMed: IInterventionMedication,
  horarios: IIntervecoesMedicamentosHorarios[] | null,
  idMedicamento: number | null = null
): string[] => {
  let result = [];
  try {
    const posology = intervMed.posologia ? (intervMed.posologia['name'] || intervMed.posologia).toString().toUpperCase() : '';
    const referenceTime = glb.formatTime(intervMed?.horaprimeiraDose, true);
    if (posology !== EnPosology.customized) {
      switch (posology) {
        default:
        case EnPosology.p12in12h:
          result = getItemHorario(referenceTime, 12);
          break;
        case EnPosology.p8in8h:
          result = getItemHorario(referenceTime, 8);
          break;
        case EnPosology.p6in6h:
          result = getItemHorario(referenceTime, 6);
          break;
        case EnPosology.p1perDay:
          result = getItemHorario(referenceTime, 24);
          break;
        case EnPosology.perDay:
          result = getItemHorario(referenceTime, 24);
          break;
        case EnPosology.perWeek:
          result = getItemHorario(referenceTime, 24);
          break;
        case EnPosology.sos:
          result = getItemHorario(referenceTime, 24);
          break;
        case EnPosology.hourInterval:
          result = getItemHorario(referenceTime, intervMed.intervaloHoras);
          break;
      }
    } else { // Personalizado
      const hrs = glb.ReplaceAll(intervMed.horarios, ' ', '').split(',');
      result = hrs?.sort((a, b) => glb.CompararIgnorarCapitulacaoEAcentuacao(a, b));
    }
    return result?.map(m => glb.getHHMM(m)) || [];
  } catch (error) {
    // log.Registrar('map-intervention-medication', 'timesAccordingPosology', error.message);
    return [];
  }
}

/* Retorna uma lista de horários a partir de uma hora inicial e um intervalo entre os horários. */
export const getItemHorario = (horaInicio, intervalo): string[] => {
  const result = [];
  try {
    // Existe a possibilidade do intervalo ser uma fração de um dia, mas também de ser um número de dias, exemplo a cada 72 horas
    const contHorario = intervalo <= 24 ? 24 / intervalo : intervalo;
    if (horaInicio !== '' || undefined) {
      result.push(horaInicio);
    } else {
      result.push('06:00');
    }
    for (let index = 1; index < contHorario; index++) {
      const date = moment('29/11/2018' + result[index - 1], 'DD/MM/YYYY h:m').add(intervalo, 'hours');
      result.push(moment(date).format('HH:mm'));
    }
    return result;
  } catch (error) {
    // log.Registrar('map-intervention-medication', 'getItemHorario', error.message);
    return [];
  }
}

export const extractCodPatient = (horario: IIntervecoesMedicamentosHorarios): number | null => {
  try {
    const split = horario?.codigoMedicamentoPaciente?.indexOf('__') >= 0 ?
      horario?.codigoMedicamentoPaciente?.split('__') :
      horario?.codigoMedicamentoPaciente?.split('_');
    const codPacient = split?.length === 2 ? split[1] : null;
    return +codPacient;
  } catch (error) {
    // log.Registrar('map-intervention-medication', 'extractCodPatient', error.message);
  }
  return null;
}

export const extractCodMedication = (horario: IIntervecoesMedicamentosHorarios): number | null => {
  try {
    // const split = h?.codigoMedicamentoPaciente?.split('__');
    const split = horario?.codigoMedicamentoPaciente?.indexOf('__') >= 0 ?
      horario?.codigoMedicamentoPaciente?.split('__') :
      horario?.codigoMedicamentoPaciente?.split('_');
    const codMed = split && split[0] ? split[0] : null;
    return +codMed;
  } catch (error) {
    // log.Registrar('map-intervention-medication', 'extractCodMedication', error.message);
  }
  return null;
}

export const fieldsMapInterventionMedication = {
  interventionMedicationId: { name: 'interventionMedicationId', type: 'number' },
  ocorrenciaNo: { name: 'ocorrenciaNo', type: 'number' },
  cor: { name: 'cor', type: 'string' },
  V_30212: { name: 'codigo', type: 'number' },
  V_30213: { name: 'titulo', type: 'string' },
  V_830: { name: 'TempoUso', type: 'string', relationshipField: 'name', relationshipFieldId: 'tempoUsoId' },
  V_832: { name: 'dosagem', type: 'string' },
  // V_106766: { name: 'dosagemUnidade', type: 'string' }, É um campo de relacionamento que está sendo mapeado hard codded.
  V_106765: { name: 'dosagemValor', type: 'number' },
  V_2949: { name: 'via', type: 'string', relationshipField: 'name', relationshipFieldId: 'viaId' },
  V_2952: { name: 'orientacoes', type: 'string' },
  V_3530: { name: 'posologia', type: 'string', relationshipField: 'name', relationshipFieldId: 'posologiaId' },
  V_29834: { name: 'Apresentacao', type: 'string', relationshipField: 'name', relationshipFieldId: 'apresentacaoId' },
  V_30296: { name: 'codigoPacienteNomePaciente', type: 'string' }, // // Não É utilizado mais. Concatena Id_Paciente V_29828 e Centro de CustoV_27993.
  V_28018: { name: 'tIPOMaterial', type: 'string', relationshipField: 'name', relationshipFieldId: 'tipoMaterialId' },
  V_30367: { name: 'horaprimeiraDose', type: 'string' },
  V_30263: { name: 'Id_Medicamento', type: 'number' },
  V_32859: { name: 'interromperMedicacao', type: 'boolean' },
  V_32860: { name: 'interrupcaoMotivo', type: 'string' },
  V_828: { name: 'ItemEstoque', type: 'string', relationshipField: 'name', relationshipFieldId: 'itemEstoqueId' }, // ok A label deste campo no studio é Medicamento / Terapia.
  V_109968: { name: 'isForMentalHealth', type: 'boolean' },

  // ,30277, ValorDado: convertCheckinToValorTexto(interventionMedication.lstmedicationCheckin) },`;
  V_30277: {
    name: 'MedicationCheckin', type: 'string', relationshipField: 'status', relationshipFields: [
      'medicationCheckinId',
      'tenantId',
      'index',
      'codigo',
      'ocorrenciaNo',
      'titulo',
      'habilitado',
      'cascataCheckIDClienteIDMedicamentoDataAtual',
      'horaPrescricao',
      'observacoes',
      'dataCheckin',
      'diaPrescricao',
      'identificacao',
      'codigoMedicamentoPaciente',
      'profissional',
      'codigoPaciente',
      'horaCheckin',
      'Id_Paciente',
      'IdMedicamento_IdPaciente',
      'tomou',
      'status',
      'statusmedicacao',
      'createdBy',
      'updatedBy',
      'createdAt',
      'updatedAt',
      'Id_Medicamento',
      //
    ], where: {
      dataCheckin: {
        gte: '#TODAY#',
        lt: '#TOMORROW#'
      }
    }, relationshipFieldId: 'Id_Medicamento'
  },
  V_104628: { name: 'ordem', type: 'number' },
  // V_29828: { name: ['codigoPaciente', 'Id_Paciente'], type: 'number' },
  // V_32939: { name: 'codigoHospede', type: 'number' },
  // V_32939: { name: 'CdHospedeTelaPrescricao)', type: 'number'}, // Eu acrescentei esta linha pois no studio este campo é necessário para preencher o Id_Paciente.
  // V_27993: { name: 'CentrodeCusto)', type: 'string'}, // Eu acrescentei esta linha pois ela não existia no mapeamento.
  V_29828: { name: 'Id_Paciente', type: 'number' }, // ok recebe o valor do campo CódHospede(TelaPrescrição) V_32939
  V_30320: { name: 'codPacienteNomedoPacienteCodMedicamento', type: 'string' }, // ok recebe o valor do campo CódHospede(TelaPrescrição) V_32939
  V_32935: { name: 'Id_Prescricao', type: 'number' },
  V_30214: { name: 'habilitado', type: 'boolean' },
  V_29838: { name: 'quantUtilizadaD', type: 'string' }, // A label deste campo no studio é Quant. por horário
  // V_101103: { name: 'quantUtilizadaD', type: 'string' }, // Este campo a princípio não é utilizado para nada. No studio sua label é Consumo diário
  V_104223: { name: 'quantUtilizadaGota', type: 'string' }, // Este campo contem um IF que verifica se a apresentação V_29834 e igual a Gotas e caso seja usa o valor do campo GOTAS p/ ml V_101099 senão exibe Quant. por horário V_29838.
  // V_29949: { name: 'hojedia', type: 'string' }, // Este campo não existe no cadastro de Intervenções Medicamentos.
  // V_30266: { name: 'centroCustoIDCentroCusto', type: 'string' }, // Não é utilizado para nada. Concatena Id_Paciente V_29828 e Centro de CustoV_27993
  // V_30270: { name: 'tIPOMedicamentos', type: 'string' }, // Este campo não existe no cadastro de Intervenções Medicamentos.
  // V_30271: { name: 'tipomedicamento', type: 'string' }, // Este campo não existe no cadastro de Intervenções Medicamentos.
  // V_30272: { name: 'dia', type: 'string' }, // Este campo não existe no cadastro de Intervenções Medicamentos.
  // V_30308: { name: 'validCadastro', type: 'string' }, // Este campo não existe no cadastro de Intervenções Medicamentos.
  // V_30320: { name: 'codPacienteNomedoPacienteCodMedicamento', type: 'string' }, // Atualmente não tem função. Concatena os campos V_30296 e V_30263.
  // V_30339: { name: 'tipoMedicamentosCodPaciente', type: 'string' }, // Não é utilizado. Concatena as variáveis V_34709 e V_29828. A variável V_34709 não está mapeada mas também não é utilizada no cadastro.
  V_30396: { name: 'horarios', type: 'string' },

  // V_32861: { name: 'TempoEstimado', type: 'string' }, // Eu acrescentei esta linha pois ela não existia no mapeamento. Mas para que serve?
  // V_34465: { name: 'Validade', type: 'Date' }, // Eu acrescentei esta linha pois ela não existia no mapeamento.
  // V_30401: { name: 'IdMedicamento_IdPaciente', type: 'string' }, // Eu acrescentei esta linha pois ela não existia no mapeamento. ConcatenaV_30263 e V_29828

  V_31967: { name: 'dataInicio', type: 'Date' },
  V_31968: { name: 'dataFim', type: 'Date' },
  V_35051: { name: 'intervaloDS', type: 'string' },
  // V_3470: { name: 'materialId', type: 'number' }, // Esta variável não existe. A variável do materialId é V_34709 não está mapeada, mas também não é utilizada no cadastro.
  V_32857: { name: 'medicamentoControlado', type: 'boolean' },

  // V_31571: { name: 'dataEVOLUCAO', type: 'Date' },
  // V_27993: { name: 'centroCusto', type: 'string' },
  // V_30330: { name: 'dataPRESCRICAO', type: 'Date' },
  // V_30401: { name: ['IdMedicamento_IdPaciente', 'codigoMedicamentoPaciente'], type: 'string' },
  // V_30307: { name: 'uNIDADEDENEGOCIO', type: 'string' },
  // V_828: { name: 'medicamento', type: 'string' },
  // V_30356: { name: 'medicametnoPrescrito', type: 'string' },
  // V_32935: { name: 'PrescriptionMedication', type: 'string', relationshipField: 'prescriptionMedicationOcorrenciaNo', relationshipFieldId: 'ocorrenciaNo' },
  // V_34906: { name: 'intervaloHoras', type: 'string' },
  // V_30264: { name: 'cascataCheckIDClienteIDMedicamentoDataEVOLUCAO', type: 'string' },
  // V_32858: { name: 'profissional', type: 'string', relationshipField: 'name' },
  // V_32861: { name: 'tempoEstimado', type: 'string' },
  // V_32862: { name: 'quantTempoEstimado', type: 'string' },

  // Na tela de prescrição existe também o grid de Check De Medicamentos V_30277, que não está listado neste mapeamento.
  // Na tela de prescrição existe o campo CascataCheck V_30264, que é o campo que ativa a cascata no grid Check de Medicamento.
  // Na tela de prescrição existe o campo MedicamentoPrescrito V_30326, que recebe o valor do campo Medicamento/Terapia V_828.
  // Na tela de prescrição existe o campo VerificaPosologia V_34286. Com um IF que verifica se a posologia é SOS ou PERSONALIZADO. Atualmente não é utilizado para nada.
  // Na tela de prescrição existe o campo gotaspMl V_101099. COnverte a quantidade por horário para gotas.
  // Na tela de prescrição existe o campo MaterialId V_34709. PROC que recebe o ID do material. Não uitilizamos mais este campo.
  // Na tela de prescrição existe o campo QuantTEstetimado V_32862. Com um IF que verifica se o Tipo de Material é Terapia e caso seja recebe V_32862 e senão recebe V_29838
  // Na tela de prescrição existe o campo CodPacineteNovo V_31969. Recebe o valor da variavel V_32939.

};

export const toAttributeIntervention = (c: any, lstmedicationCheckin: IMedicationCheckin[] = null, lsthorariosGrid: IIntervecoesMedicamentosHorarios[] = null): IInterventionMedication | null => {
  try {
    return {
      ocorrenciaNo: c.OcorrenciaNo,
      cor: c.cor,
      codigo: c.V_30212, // OK
      titulo: c.V_30213, // OK
      medicamento: c.V_828, // Não tem na versão gerada. não sei o que esse comentário significa, descomentei essa linha uma vez que a linha não estava funcionando.
      tempoUso: c.V_830, // OK
      dosagem: c.V_832, // OK
      dosagemValor: c.V_106765 || '', // V106765
      dosagemUnidade: c.V_106766 || '', // V106766
      via: c.V_2949, // OK
      orientacoes: c.V_2952, // OK
      posologia: c.V_3530, // OK
      centrocusto: c.V_27993, // OK
      codigoPaciente: c.V_29828, // Não tem. mas tem coPAcienteNOVO: c.V_31969
      apresentacao: c.V_29834, // OK
      dataPRESCRICAO: glb.ddMMYYYYThhmmssToDate(c.V_30330), // OK
      codigoPacienteNomePaciente: c.V_30296, // Não tem. Tem idMedicamentoIdPaciente: c.V_30401
      IdMedicamento_IdPaciente: c.V_30401, // OK
      tIPOMaterial: c.V_28018, // OK
      uNIDADEDENEGOCIO: c.V_30307, // Não tem
      horaprimeiraDose: c.V_30367, // OK
      codigoMedicamentoPaciente: c.V_30401, // OK
      Id_Paciente: c.V_29828, // OK
      Id_Medicamento: c.V_30263, // OK
      // codigomedicamento: c.V_30263, // Não tem
      dataEVOLUCAO: glb.ddMMYYYYThhmmssToDate(c.V_31571), // OK
      interroperMedicacao: glb.getBoolean(c.V_32859), // OK
      interrupcaoMotivo: c.V_32860, // OK mas chama motivoOrientacoes
      medicamentoControlado: c.V_32857, // OK
      medicametnoPrescrito: c.V_30356, // OK
      ordem: c.V_104628, // OK
      codigoHospede: c.V_32939, // OK mas chama codHospedTelaPrescricao
      Id_Prescricao: c.V_32935, // // OK
      habilitado: glb.getBoolean(c.V_30214), // OK
      lstmedicationCheckin,
      lsthorariosGrid,
      intervaloHoras: c.V_34906 || 0,
      quantUtilizadaD: glb.getSomeNumber('-1', c.V_29838, c.V_101103),
      quantUtilizadaGota: glb.getSomeNumber('-1', c.V_104223),
      hojedia: glb.ddMMYYYYThhmmssToDate(c.V_29949),
      cascataCheckIDClienteIDMedicamentoDataEVOLUCAO: c.V_30264,
      centroCustoIDCentroCusto: c.V_30266,
      tIPOMedicamentos: c.V_30270,
      tipomedicamento: c.V_30271,
      dia: c.V_30272,
      validCadastro: c.V_30308,
      codPacienteNomedoPacienteCodMedicamento: c.V_30320,
      tipoMedicamentosCodPaciente: c.V_30339,
      // alteracao barbara
      horarios: c.V_30396,
      calc__isSOS: isSOS(c),
      // descricao: any;
      dataInicio: glb.ddMMYYYYThhmmssToDate(c.V_31967),
      dataFim: glb.ddMMYYYYThhmmssToDate(c.V_31968),
      profissional: c.V_32858,
      tempoEstimado: c.V_32861,
      quantTempoEstimado: c.V_32862,
      //  codMedPacTM: any;
      // cascataTmatHospe: any;
      intervaloDS: c.V_35051,
      materialId: c.V_34709,
      isForMentalHealth: c.V_109968,
    } as IInterventionMedication;
  } catch (error) {
    // log.Registrar('map-intervention-medication', 'toAttribute', error.message);
  }
  return null;
}

export const isSOS = (item: any): boolean | null => {
  try {
    return glb.isEqual(item.V_3530, 'SOS');
  } catch (error) {
    // log.Registrar('map-intervention-medication', 'isSOS', error.message);
  }
}

export const mapToFormIntervention = (interventionMedication: IInterventionMedication): IForm[] | null => {
  try {
    return interventionMedication ? [
      { VariavelNo: 828, ValorDado: interventionMedication.medicamento || '' },
      { VariavelNo: 830, ValorDado: interventionMedication.tempoUso || '' },
      { VariavelNo: 832, ValorDado: interventionMedication.dosagem || '' },
      // Acrescentado em 12 jan 2022
      { VariavelNo: 106766, ValorDado: interventionMedication.dosagemUnidade || '' },
      { VariavelNo: 106765, ValorDado: interventionMedication.dosagemValor || '' },

      { VariavelNo: 2949, ValorDado: interventionMedication.via || '' },
      { VariavelNo: 2952, ValorDado: interventionMedication.orientacoes || '' },
      { VariavelNo: 3530, ValorDado: interventionMedication.posologia || '' },
      { VariavelNo: 27993, ValorDado: interventionMedication.centrocusto || '' },
      { VariavelNo: 29828, ValorDado: interventionMedication.codigoPaciente || '' },
      { VariavelNo: 29834, ValorDado: interventionMedication.apresentacao || '' },
      { VariavelNo: 29838, ValorDado: interventionMedication.quantUtilizadaD || '' },
      { VariavelNo: 104223, ValorDado: interventionMedication.quantUtilizadaGota || '' },
      { VariavelNo: 29949, ValorDado: getDate(interventionMedication.hojedia) },
      { VariavelNo: 30212, ValorDado: interventionMedication.codigo || '' },
      { VariavelNo: 30213, ValorDado: interventionMedication.titulo || '' },
      { VariavelNo: 30214, ValorDado: glb.getReverseBoolean(interventionMedication.habilitado) },
      // { VariavelNo: 30263, ValorDado: interventionMedication.codigomedicamento || '' },
      {
        VariavelNo: 30264,
        ValorDado: interventionMedication.cascataCheckIDClienteIDMedicamentoDataEVOLUCAO || ''
      },
      { VariavelNo: 30266, ValorDado: interventionMedication.centroCustoIDCentroCusto || '' },
      { VariavelNo: 30270, ValorDado: interventionMedication.tIPOMedicamentos || '' },
      { VariavelNo: 30271, ValorDado: interventionMedication.tipomedicamento || '' },
      { VariavelNo: 30272, ValorDado: interventionMedication.dia || '' },
      { VariavelNo: 30296, ValorDado: interventionMedication.codigoPacienteNomePaciente || '' },
      { VariavelNo: 28018, ValorDado: interventionMedication.tIPOMaterial || '' },
      { VariavelNo: 30307, ValorDado: interventionMedication.uNIDADEDENEGOCIO || '' },
      { VariavelNo: 30308, ValorDado: interventionMedication.validCadastro || '' },
      { VariavelNo: 30320, ValorDado: interventionMedication.codPacienteNomedoPacienteCodMedicamento || '' },
      { VariavelNo: 30330, ValorDado: getDate(interventionMedication.dataPRESCRICAO) },
      { VariavelNo: 30339, ValorDado: interventionMedication.tipoMedicamentosCodPaciente || '' },
      { VariavelNo: 30356, ValorDado: interventionMedication.medicametnoPrescrito || '' },
      { VariavelNo: 30367, ValorDado: interventionMedication.horaprimeiraDose || '' },
      { VariavelNo: 30401, ValorDado: interventionMedication.codigoMedicamentoPaciente || '' },
      { VariavelNo: 31571, ValorDado: getDate(interventionMedication.dataEVOLUCAO) },
      { VariavelNo: 30277, ValorDado: convertCheckinToValorTexto(interventionMedication.lstmedicationCheckin) },
      { VariavelNo: 29828, ValorDado: interventionMedication.Id_Paciente || '' },
      { VariavelNo: 30263, ValorDado: interventionMedication.Id_Medicamento || '' },
      { VariavelNo: 30401, ValorDado: interventionMedication.IdMedicamento_IdPaciente || '' },
      { VariavelNo: 32859, ValorDado: glb.getReverseBoolean(interventionMedication.interroperMedicacao) },
      { VariavelNo: 32860, ValorDado: interventionMedication.interrupcaoMotivo || '' },
      // cal__isSOS
      // intervaloHoras
      // horarios
      // { VariavelNo: 30396, ValorDado: convertToValorTextoHorarios(interventionMedication.lsthorariosGrid) },
      { VariavelNo: 30396, ValorDado: interventionMedication.horarios },
      { VariavelNo: 31967, ValorDado: getDate(interventionMedication.dataInicio) },
      { VariavelNo: 31968, ValorDado: getDate(interventionMedication.dataFim) },
      { VariavelNo: 32857, ValorDado: interventionMedication.medicamentoControlado },
      { VariavelNo: 32858, ValorDado: interventionMedication.profissional || '' },
      { VariavelNo: 32861, ValorDado: interventionMedication.tempoEstimado || '' },
      { VariavelNo: 32862, ValorDado: interventionMedication.quantTempoEstimado || '' },
      { VariavelNo: 32939, ValorDado: interventionMedication.codigoHospede || '' },
      { VariavelNo: 35051, ValorDado: interventionMedication.intervaloDS || '' },
      { VariavelNo: 32935, ValorDado: interventionMedication.Id_Prescricao || '' },
      { VariavelNo: 104628, ValorDado: interventionMedication.ordem || 0 },
      { VariavelNo: 34709, ValorDado: interventionMedication.materialId || '' },
      { VariavelNo: 109968, ValorDado: glb.getReverseBoolean(interventionMedication.isForMentalHealth) }
    ] : null;
  } catch (error) {
    // log.Registrar('map-intervention-medication', 'mapToForm', error.message);
  }
  return null;
}

export const getDate = (value: string | Date): string | null => {
  try {
    const dt = glb.getTypedValue(value);
    if (dt.type === EnTypedValue.Date) {
      return glb.dateToYYYYMMddThhmmss(dt.value);
    }
  } catch (error) {
    // log.Registrar('map-intervention-medication', 'getDate', error.message);
  }
  return null;
}

export const convertCheckinToValorTexto = (medicationCheckin: IMedicationCheckin[]): string => {
  try {
    let valorTexto = '<![CDATA[<Items>';
    if (medicationCheckin) {
      medicationCheckin.forEach((f) => {
        valorTexto += `<Item><index>${f.codigo}</index>`;
        if (f.cascataCheckIDClienteIDMedicamentoDataAtual)
          valorTexto += `<V_30264>${f.cascataCheckIDClienteIDMedicamentoDataAtual}</V_30264>`;
        if (f.horaPrescricao) valorTexto += `<V_30273>${f.horaPrescricao}</V_30273>`;
        if (f.horaCheckin) valorTexto += `<V_34166>${f.horaCheckin}</V_34166>`;
        if (f.status) valorTexto += `<V_30274>${f.status}</V_30274>`;
        if (f.observacoes) valorTexto += `<V_30275>${f.observacoes}</V_30275>`;
        if (f.statusmedicacao) valorTexto += `<V_30276>${f.statusmedicacao}</V_30276>`;
        if (f.dataCheckin) valorTexto += `<V_30330>${f.dataCheckin}</V_30330>`;
        if (f.diaPrescricao) valorTexto += `<V_30331>${f.diaPrescricao}</V_30331>`;
        if (f.codigo) valorTexto += `<V_30351>${f.codigo}</V_30351>`;
        if (f.identificacao) valorTexto += `<V_30264>${f.identificacao}</V_30264>`;
        if (f.titulo) valorTexto += `<V_30352>${f.titulo}</V_30352>`;
        if (f.habilitado) valorTexto += `<V_30353>${f.habilitado}</V_30353>`;
        if (f.codigoMedicamentoPaciente) valorTexto += `<V_30401>${f.codigoMedicamentoPaciente}</V_30401>`;
        if (f.profissional) valorTexto += `<V_31927>${f.profissional}</V_31927>`;
        if (f.Id_Paciente) valorTexto += `<V_29828>${f.Id_Paciente}</V_29828>`;
        if (f.Id_Medicamento) valorTexto += `<V_30263>${f.Id_Medicamento}</V_30263>`;
        if (f.IdMedicamento_IdPaciente) valorTexto += `<V_30401>${f.IdMedicamento_IdPaciente}</V_30401>`;
        valorTexto += '</Item>';
      });
    }
    valorTexto += '</Items>]]>';
    return valorTexto;
  } catch (error) {
    // log.Registrar('map-intervention-medication', 'convertCheckinToValorTexto', error.message);
  }
  return '';
}

export const mapToMedicationCheckin = (m: any) => ({
  cascataCheckIDClienteIDMedicamentoDataAtual: m.V_30264,
  horaPrescricao: m.V_30273,
  horaCheckin: m.V_34166,
  status: m.V_30274,
  observacoes: m.V_30275,
  statusmedicacao: m.V_30276,
  dataCheckin: glb.ddMMYYYYThhmmssToDate(m.V_30330),
  diaPrescricao: m.V_30331,
  codigo: m.V_30351,
  identificacao: m.V_30264,
  titulo: m.V_30352,
  habilitado: glb.getBoolean(m.V_30353, 'SIM'),
  codigoMedicamentoPaciente: m.V_30401,
  profissional: m.V_31927,
  Id_Paciente: m.V_29828,
  Id_Medicamento: m.V_30263,
  IdMedicamento_IdPaciente: m.V_30401
} as IMedicationCheckin);
